import { motion, useCycle, Variants } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";

const disclosure: Variants = {
    open: {
        opacity: 1,
        height: "auto"
    },
    closed: {
        opacity: 0,
        transition: {
            type: "spring"
        },
        height: "0px",
        margin: "0px"
    }
};


interface DisclosureProps extends React.PropsWithChildren {
    title: string;
}

const Disclosure: React.FC<DisclosureProps> = ({ title, children }) => {
    const [isToggled, setIsToggled] = useState(false);
    const [isOpen, toggleOpen] = useCycle(false, true);

    return (
        <div className="border-[1.2px] rounded-lg border-purple p-5 " >
            <motion.div
                initial={false}
                animate={isOpen ? "open" : "closed"}
            >
                <div className="flex justify-between cursor-pointer" onClick={() => { toggleOpen(); setIsToggled(!isToggled) }} >
                    <h4 className="font-semibold">
                        {title}
                    </h4>
                    <div>
                        {!isToggled ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="#F2F5F7" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M13 13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H11V6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13Z" fill="black" fillOpacity="0.7" />
                            <mask id="mask0_839_544" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13 13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H11V6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_839_544)">
                                <rect width="24" height="24" fill="#491E47" />
                            </g>
                        </svg>
                            : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" fill="#F2F5F7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6Z" fill="black" fillOpacity="0.7" />
                                <mask id="mask0_1047_114" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="5" y="11" width="14" height="2">
                                    <path fillRule="evenodd" clip-rule="evenodd" d="M6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_1047_114)">
                                    <rect width="24" height="24" fill="#491E47" />
                                </g>
                            </svg>
                        }

                    </div>
                </div>
                <motion.div variants={disclosure} className={`text-neutral-500 mt-6 ${isOpen ? "" : "hidden"}`}>
                    {children}
                </motion.div>
            </motion.div >
        </div >
    )
}

const FAQ = () => {
    return (
        <div>
            <div className="py-24 te">
                <div className="text-center">
                    <h1 className="md:text-5xl text-purple text-3xl font-bold">
                        Frequently Asked Questions
                    </h1>
                    <div className="md:w-8/12 mx-auto">
                        <p className="md:text-3xl mt-6">
                            At Reneers, our work culture is built on trust, well-being, results, and a healthy work-life balance.
                        </p>
                    </div>
                </div>

                <div className="mt-12 md:w-8/12 mx-auto flex flex-col gap-6 md:px-0 px-4 text-left  md:text-lg">
                    <Disclosure
                        title="How can I get a loan from Renners?"
                    >
                        <div>
                            <div>
                                At Renners, getting a loan is easy and simple! Follow these steps to access a loan with us:
                            </div>
                            <ul className="ml-2 list-disc">

                                <li> •	Download the Renners App from Google Play Store using this link: <a className="text-blue-600" href="https://play.google.com/store/apps/details?id=com.bloocode.renners">https://play.google.com/store/apps/details?id=com.bloocode.renners</a></li>

                                <li> •	Download the Renners App from Apple Store using this link: <a className="text-blue-600" href="https://apps.apple.com/ng/app/renners-quick-instant-loan/id1663549425">https://apps.apple.com/ng/app/renners-quick-instant-loan/id1663549425</a></li>

                                <li> •	Sign up with the phone number linked to your BVN</li>

                                <li> •	Answer a few questions and confirm your identity to receive a loan offer.</li>

                                <li> •	Once you accept the offer, you will receive your loan within 5 minutes.</li>
                            </ul>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How much can I borrow and for how long?"
                    >
                        <div>
                            <div>
                                Renners offers loans of up to N1,000,000 for a period of up to 18 months. The amount you can borrow, and the length of the repayment period depends on your lending history and positive behaviour.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Do I need documents or collateral to get a loan from Renners?"
                    >
                        <div>
                            <div>
                                No, you do not need any document or collateral to get a loan from Renners. You will only need to share your BVN details during the loan application and connect your card or bank account to authorize us to debit your account for repayment.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Can I get another loan immediately?"
                    >
                        <div>
                            <div>
                                Yes, as soon as your previous loan repayment is confirmed, you can reapply immediately and get another loan in seconds.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Can I pay before the due date?"
                    >
                        <div>
                            <div>

                                Yes, we encourage you to make payment before the due date as this will boost your credit score and give you a chance of getting a higher loan in the future.

                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="What happens if I lose my phone?"
                    >
                        <div>
                            <div>

                                If you lose your phone, send an email to support@Rennersng.com to get the details of your loan and your repayment date. You can also sign in from another phone using your phone number and password.

                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Can I get a loan top-up?"
                    >
                        <div>
                            <div>
                                Yes, to request for a Top-up loan, kindly update your APP to the latest version, click on "Manage loan" on your APP home page. If you are eligible for a Top-up loan, you'll find it displayed for you to apply. If the offer is not available for you at the moment, please note that we're working on making it available for you as soon as possible.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="What happens if I fail to pay on time?"
                    >
                        <div>
                            <div>
                                If you fail to pay on time, your credit score will be affected and chances of getting a loan in the future gets affected. We encourage prompt repayment to avoid late fees, suspension of your Renners What happens if I fail to pay on time?
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How do I make a loan repayment?"
                    >
                        <div>
                            <div>
                                You can make loan repayments via two options on the Renners App:
                                <ul className="li list-items">
                                    <li> Repayment via your debit card connected to the app</li>
                                    <li> Repayment via your Renners wema account.</li>
                                </ul>
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How do I check my loan balance and payment date?"
                    >
                        <div>
                            <div>

                                Log in to the Renners App to access your customized dashboard that contains your credit status and/or all the details concerning your current balance.

                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="What happens if I don't pay back?"
                    >
                        <div>
                            <div>
                                If you don't pay back, you risk late fees, suspension of your Renners account, and report to national credit bureaus as required by law (CRC, First Central).
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Where will my loan get disbursed?"
                    >
                        <div>
                            <div>
                                Your loan will be paid into your provided bank account.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How do to Repayment via your debit card?"
                    >
                        <div>
                            <div>

                                To make payment via debit card connected to the app, please click on "Repay loan" on the homepage, select the amount to repay, and select the card connected to the app to make payment
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How to do Repayment via Renners Bank Transfer?"
                    >
                        <div>
                            <div>
                                To make payment via transfer to the Renners Bank, please click on "Fund Account" on the homepage to confirm your Renners Bank account number to make the transfer. After the transfer is made into your Renner wema bank account, it will reflect on the app as a deposit.
                                To repay your loan, all you have to do is click on the Repay loan button, select the Renner option to initiate your repayment and your loan will be repaid instantly!
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Can I repay my loan through my Renner bank account?"
                    >
                        <div>
                            <div>
                                Yes. If you have made a transfer of your loan repayment into your Wema bank account, please note that this transfer will reflect on your app as a deposit into your Wema account. After the transfer has been successfully made to your Renners Loan will be cleaned, you will then receive a notification of payment received.
                            </div>
                            <div className="mt-4">
                                All you have to do is then click on the Repay Loan button, select the Renners wema account option to initiate your loan repayment via your Renners account. Your loan will be repaid instantly!
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="Can I extend my loan more than once?"
                    >
                        <div>
                            <div>

                                Yes, you can extend your loan as many times as you want, as long as you meet the eligibility criteria for extension.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How much does it cost to extend my loan?"
                    >
                        <div>
                            <div>
                                Extending your loan comes with an additional fee, which is calculated based on the amount you are extending and the number of days. The exact fee will be displayed to you on the app when you request for an extension.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="How long can I extend my loan for?"
                    >
                        <div>
                            <div>
                                The duration of loan extension varies based on your eligibility and the terms of your loan. You can log in to the app and check your eligibility for loan extension and the available options.
                            </div>
                        </div>
                    </Disclosure>
                    <Disclosure
                        title="What happens if I can't repay my loan even after extension?"
                    >
                        <div>
                            <div>
                                We understand that situations may arise that could make it difficult for you to repay your loan, even after an extension. In such cases, please reach out to us immediately by sending an email to support@Rennersng.com or calling our customer care line on +234 908 807 2000.
                            </div>

                            <div className="mt-4">
                                We will work with you to find a solution that is mutually beneficial and ensures that you are able to repay your loan as soon as possible. It is important to note that failure to repay your loan can result in legal action, including but not limited to collection calls, visits from debt collectors, and legal proceedings. It can also have a negative impact on your credit score and make it difficult for you to access credit in the future.
                            </div>
                        </div>
                    </Disclosure>

                </div>
            </div>
            <div className="mt-20 pb-36">
                <div className="text-center">
                    <h4 className="text-lg font-semibold">
                        Still have a question?
                    </h4>
                    <Link to="/contact" replace={true} className="mt-6 rounded-lg bg-purple px-8 py-3 font-light text-white inline-block">
                        <div className="flex items-center gap-2">
                            <span>
                                Contact Us
                            </span>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13L7 7L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </Link>
                </div>
            </div>
        </div>
    )
}

export default FAQ;