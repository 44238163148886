import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollListener = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <></>
    )
}

export default ScrollListener;