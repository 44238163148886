import { Link } from "react-router-dom";
import { getIcon, getImage, getLogo } from "../../utils";

const Footer = () => {
    return (
        <>
            <footer className="bg-[#242424] overflow-clip">
                <div className="md:pt-24 md:pb-12 py-12 md:px-12 px-4 relative">
                    <svg className="absolute z-10 left-0" width="46" height="149" viewBox="0 0 46 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="-68" width="114" height="149" fill="#FFFFFF" fillOpacity="0.63" />
                    </svg>

                    <div className="md:pl-36 pl-4 relative z-20">
                        <div className="flex items-center gap-2">
                            <img src={getImage("renners-icon.png")} alt="" />
                            <div className="font-medium text-white">RENNERS</div>
                        </div>
                        <div className="mt-4">
                            <p className="text-white font-medium text-lg">
                                We are in business to provide you the financial <br /> support to power your business and growth.
                            </p>
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col md:justify-between text-white md:pl-36 mt-20 md:gap-0 gap-4">
                        <div className="flex md:flex-row flex-col gap-8 md:items-center">
                            <span className="text-center">
                                info@renners.com, 09088072000
                            </span>
                            <div className="flex gap-8 md:justify-start justify-center">
                                <img src={getIcon("twitter.png")} alt="" />
                                <img src={getIcon("instagram.png")} alt="" />
                                <img src={getIcon("facebook.png")} alt="" />
                                <img src={getIcon("youtube.png")} alt="" />
                            </div>
                        </div>
                        <div className="">
                            <ul className="flex md:gap-6 gap-2 md:justify-start justify-center">
                                <li className="md:font-medium md:text-base text-xs">
                                    <Link to="/contact" replace={true}> Contact</Link>
                                </li>
                                <li className="md:font-medium md:text-base text-xs">
                                    <Link to="/blog" replace={true}> Blog</Link>
                                </li>
                                <li className="md:font-medium md:text-base text-xs">
                                    <Link to="/faq" replace={true}> FAQs</Link>
                                </li>
                                <li className="md:font-medium md:text-base text-xs">
                                    <Link to="/terms-and-conditions" replace={true}>  Terms of Service</Link>
                                </li>
                                <li className="md:font-medium md:text-base text-xs">
                                    <Link to="/privacy-policy" replace={true}>  Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <svg className="absolute -top-7 -rotate-90 right-5" width="55" height="100" viewBox="0 0 55 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M85.3037 0.303711C74.1413 0.303711 63.0883 2.5023 52.7756 6.77396C42.4629 11.0456 33.0926 17.3067 25.1996 25.1996C17.3067 33.0926 11.0456 42.4629 6.77395 52.7756C2.5023 63.0883 0.30371 74.1414 0.303711 85.3037C0.303711 96.4661 2.5023 107.519 6.77395 117.832C11.0456 128.144 17.3067 137.515 25.1996 145.408C33.0926 153.301 42.4629 159.562 52.7756 163.833C63.0883 168.105 74.1414 170.304 85.3037 170.304L85.3037 85.3037L85.3037 0.303711Z" fill="#4B9388" fill-opacity="0.4" />
                    </svg>
                </div>
                <div className="md:px-12 px-4 relative">
                    <div className="border-t border-[#4B4B4B] text-white pl-4 md:pl-36 py-5">
                        <div className="flex justify-between">
                            <p className="md:text-base text-xs">
                                © Copyright 2022 Renners. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;