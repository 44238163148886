import { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter, Routes, Navigate, Outlet } from "react-router-dom"
import App from "../App";
import AboutPage from "../pages/About";
import Blogs from "../pages/Blog";
import CareerPage from "../pages/Career";
import ContactPage from "../pages/Contact";
import FAQ from "../pages/FAQ";
import HomePage from "../pages/Home";
import MediaPage from "../pages/Media";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import TermsAndConditionsPage from "../pages/TermsAndCondition";
import { getAbsoluteUrl, getSVG } from "../utils";

const AppRoutes = () => {

    return (
        <>
            <BrowserRouter>
                <Routes >
                    <Route path="" element={<App />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/career" element={<CareerPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/blogpost" element={<Blogs />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/gallery" element={<MediaPage />} />
                        <Route path="/faq" element={<FAQ />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
};

export default AppRoutes;