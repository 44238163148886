import { LegacyRef, useEffect, useRef, useState, } from "react"
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { getAbsoluteUrl, getIcon, getLogo, getSVG, useDimensions } from "../utils";
import { Link } from "react-router-dom";
import { motion, useCycle, Variants } from "framer-motion";
import { MenuToggle } from "./MenuToggle";

const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {
        clipPath: "circle(30px at 40px 40px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};

const dropdown: Variants = {
    open: {
        opacity: 1,
        top: "4rem",

    },
    closed: {
        opacity: 0,
        top: "-20px",
        transition: {
            type: "spring"
        }

    }
};

const Navbar = () => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);

    return (
        <header className="sticky top-0 left-0 bg-white z-30">

            <motion.div className="background" variants={sidebar} />
            <motion.nav
                initial={false}
                animate={isOpen ? "open" : "closed"}
                custom={height}
                ref={containerRef}
                className="flex md:px-20 px-4 md:py-6 py-3 justify-between">
                <div className="flex gap-20">
                    <Link to="/" onClick={() => { }} replace={true}>
                        <img className="" src={getLogo("logo.png")} alt="" />
                    </Link>
                    <ul className="hidden md:flex gap-6 items-center">
                        <li>
                            <Popover className="relative">
                                <Popover.Button className="outline-0  font-medium">
                                    <div className="flex gap-2 items-center">
                                        <div>Company</div>
                                        <svg width="15" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 7L13 1" stroke="#5A5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </div>
                                </Popover.Button>

                                <Popover.Panel className="absolute top-8 z-10 bg-white rounded-lg drop-shadow-[0_1px_3px_rgba(0,0,0,0.45)]">
                                    <div className="flex py-4 rounded-lg items-center jusfify-between">
                                        <div className="px-4 flex gap-2  border-r border-slate-100 py-3">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.3333 7.33333C15.3333 5.86267 14.1373 4.66667 12.6666 4.66667V3.33333C12.6666 1.49533 11.1713 0 9.33329 0H6.66663C4.82863 0 3.33329 1.49533 3.33329 3.33333V4.66667C1.86263 4.66667 0.666626 5.86267 0.666626 7.33333V11C0.666626 11.9193 1.41396 12.6667 2.33329 12.6667H7.33329V14.6667H4.66663C4.29863 14.6667 3.99996 14.9647 3.99996 15.3333C3.99996 15.702 4.29863 16 4.66663 16H11.3333C11.7013 16 12 15.702 12 15.3333C12 14.9647 11.7013 14.6667 11.3333 14.6667H8.66663V12.6667H13.6666C14.586 12.6667 15.3333 11.9193 15.3333 11V7.33333ZM2.33329 9.33333C2.21929 9.33333 2.10796 9.34533 1.99996 9.36733V7.33333C1.99996 6.598 2.59796 6 3.33329 6V8H12.6666V6C13.402 6 14 6.598 14 7.33333V9.36733C13.892 9.34533 13.7806 9.33333 13.6666 9.33333H2.33329Z" fill="#4896D1" />
                                            </svg>
                                            <Link to="/about" replace={true}>
                                                <Popover.Button className="outline-0 whi whitespace-nowrap font-medium">
                                                    <div>
                                                        About Us
                                                    </div>
                                                </Popover.Button>
                                            </Link>
                                        </div>
                                        <div className="px-8 flex gap-2 py-3">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.6667 2.66667H11.9333C11.7786 1.91428 11.3692 1.23823 10.7742 0.752479C10.1791 0.266727 9.4348 0.000969683 8.66667 0L7.33333 0C6.5652 0.000969683 5.82088 0.266727 5.22583 0.752479C4.63079 1.23823 4.2214 1.91428 4.06667 2.66667H3.33333C2.4496 2.66773 1.60237 3.01925 0.97748 3.64415C0.352588 4.26904 0.00105857 5.11627 0 6L0 8H16V6C15.9989 5.11627 15.6474 4.26904 15.0225 3.64415C14.3976 3.01925 13.5504 2.66773 12.6667 2.66667ZM5.456 2.66667C5.59339 2.27806 5.84749 1.94139 6.18353 1.70273C6.51958 1.46406 6.92116 1.33504 7.33333 1.33333H8.66667C9.07884 1.33504 9.48042 1.46406 9.81647 1.70273C10.1525 1.94139 10.4066 2.27806 10.544 2.66667H5.456Z" fill="#4896D1" />
                                                <path d="M8.66667 10C8.66667 10.1768 8.59643 10.3464 8.47141 10.4714C8.34638 10.5964 8.17681 10.6667 8 10.6667C7.82319 10.6667 7.65362 10.5964 7.5286 10.4714C7.40357 10.3464 7.33333 10.1768 7.33333 10V9.33334H0V12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.999 3.33333 16H12.6667C13.5504 15.999 14.3976 15.6474 15.0225 15.0225C15.6474 14.3976 15.9989 13.5504 16 12.6667V9.33334H8.66667V10Z" fill="#4896D1" />
                                            </svg>
                                            <Link to="/career" replace={true}>
                                                <Popover.Button className="outline-0  font-medium">
                                                    Career
                                                </Popover.Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </li>
                        <li>
                            <Popover className="relative">
                                <Popover.Button className="outline-0  font-medium">
                                    <div className="flex gap-2 items-center">
                                        <div>Help</div>
                                        <svg width="15" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 7L13 1" stroke="#5A5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>


                                    </div>
                                </Popover.Button>

                                <Popover.Panel className="absolute top-8  z-10 bg-white rounded-lg drop-shadow-[0_1px_3px_rgba(0,0,0,0.45)]">
                                    <div className="flex py-4 rounded-lg items-center jusfify-between">
                                        <div className="px-8 flex gap-2  border-r border-slate-100 py-3">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.5573 0.0186673C6.24063 -0.134666 3.97397 0.710667 2.3393 2.34733C0.704634 3.98467 -0.1407 6.252 0.019967 8.56933C0.3133 12.8053 4.05463 16 8.72263 16H12.6673C14.5053 16 16.0006 14.5047 16.0006 12.6667V8.22667C16 3.90733 12.7306 0.302667 8.5573 0.0186673ZM7.9353 12.6667C7.3833 12.6667 6.9353 12.2187 6.9353 11.6667C6.9353 11.1147 7.3833 10.6667 7.9353 10.6667C8.4873 10.6667 8.9353 11.1147 8.9353 11.6667C8.9353 12.2187 8.4873 12.6667 7.9353 12.6667ZM9.22063 8.32533C8.8733 8.51667 8.60197 8.95933 8.60197 9.33333C8.60197 9.702 8.3033 10 7.9353 10C7.5673 10 7.26863 9.702 7.26863 9.33333C7.26863 8.47667 7.80663 7.582 8.5773 7.15733C9.08597 6.87733 9.34863 6.32133 9.24663 5.74067C9.15463 5.21667 8.70663 4.76867 8.18263 4.67667C7.7793 4.60533 7.3853 4.70933 7.07797 4.96667C6.7753 5.22133 6.60197 5.59333 6.60197 5.988C6.60197 6.35667 6.3033 6.65467 5.9353 6.65467C5.5673 6.65467 5.26863 6.35667 5.26863 5.988C5.26863 5.198 5.61597 4.454 6.22063 3.946C6.82597 3.438 7.6253 3.22733 8.4133 3.36333C9.48863 3.552 10.372 4.43467 10.5606 5.51067C10.7593 6.64333 10.2213 7.77467 9.2213 8.326L9.22063 8.32533Z" fill="#4896D1" />
                                            </svg>

                                            <Link to="/faq" replace={true}>
                                                <Popover.Button className="outline-0  font-medium">
                                                    FAQs
                                                </Popover.Button>
                                            </Link>
                                        </div>
                                        <div className="px-8  flex gap-2 py-3">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9693 3.69467L10.3573 9.30667C9.73158 9.93084 8.88383 10.2814 8 10.2814C7.11617 10.2814 6.26842 9.93084 5.64267 9.30667L0.0306667 3.69467C0.0213333 3.8 0 3.89534 0 4.00001V12C0.00105857 12.8837 0.352588 13.731 0.97748 14.3559C1.60237 14.9808 2.4496 15.3323 3.33333 15.3333H12.6667C13.5504 15.3323 14.3976 14.9808 15.0225 14.3559C15.6474 13.731 15.9989 12.8837 16 12V4.00001C16 3.89534 15.9787 3.8 15.9693 3.69467Z" fill="#4896D1" />
                                                <path d="M9.41464 8.36399L15.504 2.27399C15.209 1.78487 14.7929 1.38001 14.296 1.09848C13.799 0.816939 13.2378 0.668217 12.6666 0.666656H3.33331C2.76212 0.668217 2.20097 0.816939 1.70399 1.09848C1.20701 1.38001 0.790957 1.78487 0.495972 2.27399L6.58531 8.36399C6.96102 8.7382 7.46969 8.9483 7.99997 8.9483C8.53025 8.9483 9.03892 8.7382 9.41464 8.36399Z" fill="#4896D1" />
                                            </svg>

                                            <Link to="/contact" replace={true}>
                                                <Popover.Button className="outline-0  font-medium">
                                                    Contact
                                                </Popover.Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </li>
                        <li>
                            <Popover className="relative">
                                <Popover.Button className="outline-0  font-medium">
                                    <div className="flex gap-2 items-center">
                                        <div>Media</div>
                                        <svg width="15" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 7L13 1" stroke="#5A5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </div>
                                </Popover.Button>

                                <Popover.Panel className="absolute top-8  z-10 bg-white rounded-lg drop-shadow-[0_1px_3px_rgba(0,0,0,0.45)]">
                                    <div className="flex py-4 rounded-lg items-center jusfify-between">
                                        <div className="px-8 flex gap-2  border-r border-slate-100 py-3">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.5573 0.0186673C6.24063 -0.134666 3.97397 0.710667 2.3393 2.34733C0.704634 3.98467 -0.1407 6.252 0.019967 8.56933C0.3133 12.8053 4.05463 16 8.72263 16H12.6673C14.5053 16 16.0006 14.5047 16.0006 12.6667V8.22667C16 3.90733 12.7306 0.302667 8.5573 0.0186673ZM7.9353 12.6667C7.3833 12.6667 6.9353 12.2187 6.9353 11.6667C6.9353 11.1147 7.3833 10.6667 7.9353 10.6667C8.4873 10.6667 8.9353 11.1147 8.9353 11.6667C8.9353 12.2187 8.4873 12.6667 7.9353 12.6667ZM9.22063 8.32533C8.8733 8.51667 8.60197 8.95933 8.60197 9.33333C8.60197 9.702 8.3033 10 7.9353 10C7.5673 10 7.26863 9.702 7.26863 9.33333C7.26863 8.47667 7.80663 7.582 8.5773 7.15733C9.08597 6.87733 9.34863 6.32133 9.24663 5.74067C9.15463 5.21667 8.70663 4.76867 8.18263 4.67667C7.7793 4.60533 7.3853 4.70933 7.07797 4.96667C6.7753 5.22133 6.60197 5.59333 6.60197 5.988C6.60197 6.35667 6.3033 6.65467 5.9353 6.65467C5.5673 6.65467 5.26863 6.35667 5.26863 5.988C5.26863 5.198 5.61597 4.454 6.22063 3.946C6.82597 3.438 7.6253 3.22733 8.4133 3.36333C9.48863 3.552 10.372 4.43467 10.5606 5.51067C10.7593 6.64333 10.2213 7.77467 9.2213 8.326L9.22063 8.32533Z" fill="#4896D1" />
                                            </svg>

                                            <Link to="/blogpost" replace={true}>
                                                <Popover.Button className="outline-0  font-medium">
                                                    Blog
                                                </Popover.Button>
                                            </Link>
                                        </div>
                                        <div className="px-8  flex gap-2 py-3">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1985_424)">
                                                    <path d="M15.9693 3.69464L10.3573 9.30664C9.73158 9.93081 8.88383 10.2813 8 10.2813C7.11617 10.2813 6.26842 9.93081 5.64267 9.30664L0.0306667 3.69464C0.0213333 3.79997 0 3.89531 0 3.99997V12C0.00105857 12.8837 0.352588 13.7309 0.97748 14.3558C1.60237 14.9807 2.4496 15.3322 3.33333 15.3333H12.6667C13.5504 15.3322 14.3976 14.9807 15.0225 14.3558C15.6474 13.7309 15.9989 12.8837 16 12V3.99997C16 3.89531 15.9787 3.79997 15.9693 3.69464Z" fill="#4896D1" />
                                                    <path d="M9.41464 8.36402L15.504 2.27402C15.209 1.7849 14.7929 1.38005 14.296 1.09851C13.799 0.816969 13.2378 0.668247 12.6666 0.666687H3.33331C2.76212 0.668247 2.20097 0.816969 1.70399 1.09851C1.20701 1.38005 0.790957 1.7849 0.495972 2.27402L6.58531 8.36402C6.96102 8.73823 7.46969 8.94833 7.99997 8.94833C8.53025 8.94833 9.03892 8.73823 9.41464 8.36402Z" fill="#4896D1" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1985_424">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            <Link to="/gallery" replace={true}>
                                                <Popover.Button className="outline-0  font-medium">
                                                    Gallery
                                                </Popover.Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </li>
                    </ul>
                </div>

                <div className="md:block hidden self-center">
                    <ul className="flex gap-2  text-[#2C3E50] text-lg items-center">
                        <li>
                            <div className="text-xs font-medium" >
                                <div className="flex gap-2 items-center">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_129_694)">
                                            <path d="M8 0C6.23249 0.00194106 4.53792 0.704943 3.2881 1.95477C2.03828 3.20459 1.33528 4.89915 1.33334 6.66667C1.33334 10.188 6.93334 15.022 7.56934 15.562L8 15.926L8.43067 15.562C9.06667 15.022 14.6667 10.188 14.6667 6.66667C14.6647 4.89915 13.9617 3.20459 12.7119 1.95477C11.4621 0.704943 9.76752 0.00194106 8 0V0ZM8 10C7.34073 10 6.69627 9.8045 6.1481 9.43823C5.59994 9.07196 5.1727 8.55136 4.9204 7.94228C4.66811 7.33319 4.6021 6.66297 4.73072 6.01637C4.85934 5.36976 5.17681 4.77582 5.64298 4.30964C6.10916 3.84347 6.7031 3.526 7.3497 3.39738C7.9963 3.26876 8.66653 3.33478 9.27561 3.58707C9.8847 3.83936 10.4053 4.2666 10.7716 4.81477C11.1378 5.36293 11.3333 6.0074 11.3333 6.66667C11.3323 7.5504 10.9807 8.39763 10.3559 9.02252C9.73096 9.64741 8.88373 9.99894 8 10Z" fill="#4896D1" />
                                            <path d="M8 8.66666C9.10457 8.66666 10 7.77123 10 6.66666C10 5.56209 9.10457 4.66666 8 4.66666C6.89543 4.66666 6 5.56209 6 6.66666C6 7.77123 6.89543 8.66666 8 8.66666Z" fill="#4896D1" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_129_694">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div>
                                        Suite 7, 3rd Floor Lagos City Mall Onikan, Lagos State
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="text-xs font-medium" >
                                <div className="flex gap-2 items-center">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM11.7767 11.058L11.4287 11.458C11.0613 11.826 10.5773 12 10.0953 12C7.61933 12 4 8.57133 4 5.90467C4 5.42267 4.174 4.93867 4.542 4.57133L4.942 4.22333C5.23933 3.926 5.722 3.926 6.01933 4.22333L6.686 5.09133C6.98333 5.38867 6.98333 5.87133 6.686 6.16867L6.11867 6.88133C6.71933 8.37733 7.738 9.35467 9.11867 9.88133L9.83133 9.314C10.1287 9.01667 10.6113 9.01667 10.9087 9.314L11.7767 9.98067C12.074 10.278 12.074 10.7607 11.7767 11.058Z" fill="#4896D1" />
                                    </svg>

                                    <div>
                                        09088072000, 09088072002
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="self-center md:hidden block">
                    <MenuToggle toggle={() => toggleOpen()} />
                </div>

                <motion.div variants={dropdown} className={`w-10/12 md:!hidden ${isOpen ? "" : "!hidden"} bg-white rounded shadow-lg p-4 mx-auto absolute top-16`}>
                    <ul className="flex flex-col text-left gap-4 text-[#2C3E50] ">

                        <li>
                            <Link onClick={() => toggleOpen()} to="/about" replace={true}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link onClick={() => toggleOpen()} to="/career" replace={true}>
                                Career
                            </Link>
                        </li>
                        <li>
                            <Link onClick={() => toggleOpen()} to="/blogpost" replace={true}>
                                Blog post
                            </Link>
                        </li>
                        <li>
                            <Link onClick={() => toggleOpen()} to="/gallery" replace={true}>
                                Gallery
                            </Link>
                        </li>
                        <li>
                            <Link onClick={() => toggleOpen()} to="/faq" replace={true}>
                                FAQ
                            </Link>
                        </li>
                        <li>
                            <Link onClick={() => toggleOpen()} to="/contact" replace={true}>
                                Contact
                            </Link>
                        </li>

                    </ul>
                </motion.div>
            </motion.nav>
        </header>
    )

}

export default Navbar;

