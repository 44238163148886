import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { getIcon, getImage, getLogo } from "../../utils";

const ContactPage = () => {
    const handleConsultation = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            const formData = new FormData(event.target as HTMLFormElement);
            const data: any = {};
            formData.forEach((val, key) => {
                data[key] = val;
            })
            const response = await axios.post("https://rennersapi.com/api/consultations", data);
            toast(response.data.message, { type: "success" });
        } catch (error: any) {
            if (error.response)
                toast(error.response.data.message, { type: "warning" });
        }
    }

    return (
        <div>
            <div className="px-4 relative py-24 md:px-24">
                <div className="relative">
                    <div className="md:flex justify-between">
                        <div className="text-left">
                            <h4 className="text-purple font-bold text-5xl">
                                We'd love to hear from you!
                            </h4>

                            <div className="mt-12">

                                <div className="mt-10 flex flex-col gap-8">
                                    <div className="flex items-center gap-2">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_130_732)">
                                                <path d="M10 0C7.79063 0.00242633 5.67242 0.881179 4.11014 2.44346C2.54787 4.00573 1.66911 6.12394 1.66669 8.33333C1.66669 12.735 8.66669 18.7775 9.46169 19.4525L10 19.9075L10.5384 19.4525C11.3334 18.7775 18.3334 12.735 18.3334 8.33333C18.3309 6.12394 17.4522 4.00573 15.8899 2.44346C14.3276 0.881179 12.2094 0.00242633 10 0V0ZM10 12.5C9.17593 12.5 8.37035 12.2556 7.68514 11.7978C6.99994 11.34 6.46589 10.6892 6.15052 9.92785C5.83516 9.16649 5.75264 8.32871 5.91341 7.52046C6.07419 6.7122 6.47102 5.96977 7.05374 5.38705C7.63646 4.80434 8.37889 4.4075 9.18714 4.24673C9.9954 4.08596 10.8332 4.16847 11.5945 4.48384C12.3559 4.7992 13.0066 5.33325 13.4645 6.01846C13.9223 6.70366 14.1667 7.50924 14.1667 8.33333C14.1654 9.438 13.726 10.497 12.9448 11.2781C12.1637 12.0593 11.1047 12.4987 10 12.5Z" fill="#4896D1" />
                                                <path d="M10 10.8333C11.3807 10.8333 12.5 9.71404 12.5 8.33333C12.5 6.95262 11.3807 5.83333 10 5.83333C8.61929 5.83333 7.5 6.95262 7.5 8.33333C7.5 9.71404 8.61929 10.8333 10 10.8333Z" fill="#4896D1" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_130_732">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <div className="md:text-base text-sm">
                                            Suite 7, 3rd Floor Lagos City Mall Onikan, Lagos State
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 0C4.03725 0 0 4.03725 0 9C0 13.9628 4.03725 18 9 18C13.9628 18 18 13.9628 18 9C18 4.03725 13.9628 0 9 0ZM13.2488 12.4403L12.8573 12.8903C12.444 13.3042 11.8995 13.5 11.3573 13.5C8.57175 13.5 4.5 9.64275 4.5 6.64275C4.5 6.1005 4.69575 5.556 5.10975 5.14275L5.55975 4.75125C5.89425 4.41675 6.43725 4.41675 6.77175 4.75125L7.52175 5.72775C7.85625 6.06225 7.85625 6.60525 7.52175 6.93975L6.8835 7.7415C7.55925 9.4245 8.70525 10.524 10.2585 11.1165L11.0602 10.4782C11.3947 10.1438 11.9378 10.1438 12.2723 10.4782L13.2488 11.2282C13.5833 11.5627 13.5833 12.1058 13.2488 12.4403Z" fill="#4896D1" />
                                        </svg>

                                        <div className="md:text-base text-sm">
                                            09088072000, 09088072002
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_133_748)">
                                                <path d="M17.9655 4.1565L11.652 10.47C10.948 11.1722 9.99431 11.5665 9 11.5665C8.00569 11.5665 7.05197 11.1722 6.348 10.47L0.0345 4.1565C0.024 4.275 0 4.38225 0 4.5V13.5C0.00119089 14.4942 0.396661 15.4473 1.09966 16.1503C1.80267 16.8533 2.7558 17.2488 3.75 17.25H14.25C15.2442 17.2488 16.1973 16.8533 16.9003 16.1503C17.6033 15.4473 17.9988 14.4942 18 13.5V4.5C18 4.38225 17.976 4.275 17.9655 4.1565Z" fill="#4896D1" />
                                                <path d="M10.5915 9.4095L17.442 2.55825C17.1101 2.00799 16.6421 1.55253 16.083 1.2358C15.5239 0.919067 14.8926 0.751755 14.25 0.75H3.74998C3.1074 0.751755 2.47611 0.919067 1.917 1.2358C1.3579 1.55253 0.889842 2.00799 0.557983 2.55825L7.40848 9.4095C7.83116 9.83049 8.40342 10.0669 8.99998 10.0669C9.59655 10.0669 10.1688 9.83049 10.5915 9.4095Z" fill="#4896D1" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_133_748">
                                                    <rect width="18" height="18" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <div className="md:text-base text-sm">
                                            Info@rennersng.com
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-8 mt-12">
                                    <img src={getIcon("twitter.png")} alt="" />
                                    <img src={getIcon("instagram.png")} alt="" />
                                    <img src={getIcon("facebook.png")} alt="" />
                                    <img src={getIcon("youtube.png")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <img className="w-full md:block hidden" src={getImage("map.png")} alt="" />

                        </div>
                    </div>
                </div>

                <svg className="absolute right-0 bottom-0 md:h-[380px] h-[200px]" width="57" height="380" viewBox="0 0 57 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="114" height="380" fill="#672A63" fillOpacity="0.63" />
                </svg>

            </div>
            <div className="px-4 md:px-20 py-28 md:mt-12">
                <div className="flex md:flex-row flex-col ">
                    <div className="md:w-5/12">
                        <img className="w-full" src={getImage("contact.png")} alt="" />
                    </div>
                    <div className="md:w-7/12 bg-[#F5F5F5] pt-8 px-4 md:px-20">
                        <div className="text-left">
                            <h3 className="font-bold text-3xl">
                                Get Free Consultation
                            </h3>
                            <p className="text-lg">
                                Please fill the form. An account officer will reach <br /> out to you as soon as possible.
                            </p>

                            <form onSubmit={handleConsultation}>
                                <div className="mt-4 flex flex-col gap-4">
                                    <div className="flex md:flex-row flex-col gap-4">
                                        <div className="flex-1">
                                            <input name="first_name" placeholder="First Name" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full outline-none" />
                                        </div>
                                    </div>
                                    <div className="flex md:flex-row flex-col gap-4">
                                        <div className="flex-1">
                                            <input name="last_name" placeholder="Last Name" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full outline-none" />
                                        </div>
                                    </div>
                                    <div className="flex md:flex-row flex-col gap-4">
                                        <div className="flex-1">
                                            <input name="email" placeholder="Email Address" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full outline-none" />
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <input name="phone" placeholder="Phone Number" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full outline-none" />
                                    </div>
                                    <div className="">
                                        <textarea placeholder="Your business need and description" name="business_need" rows={4} cols={8} className="text-[#959595] mt-1 border-b pt-2 pb-4 border-b-[#959595] bg-transparent w-full outline-none"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button type="submit" className="px-12 py-3 text-white rounded bg-[#282828]">Submit</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ContactPage;