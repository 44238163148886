import { Link } from "react-router-dom";
import { getIcon, getImage, getLogo } from "../../utils";

const AboutPage = () => {
    return (
        <div>
            <div className="px-4 relative md:pb-0 pb-4 md:pt-36 pt-24 md:px-24 bg-[#F4F3F3]">
                <div className="text-center md:w-10/12 mx-auto container">
                    <h5 className="font-semibold text-lg">
                        ABOUT COMPANY
                    </h5>
                    <h1 className="font-bold text-2xl md:text-[3.1rem] l leading-[1.2] mt-5">
                        At Renners, we are creating <span className="text-purple"> financial accessibility </span> for all
                    </h1>
                </div>
                <div className="container">
                    <div className="text-sm text-[#5A5A5A] font-bold md:text-3xl mt-6">- Entreprenurs, MSMEs, Salary Earners, Students, Artisans.</div>
                </div>
                <div className="md:mt-24 mt-12 relative">
                    <div className="">
                        <div className="">
                            <div className="overflow-hidden">
                                <img className="w-full" src={getImage('about.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="px-4 md:px-16 container mx-auto">
                <div className="py-8 md:py-20 text-justify">
                    <p className="mt-4 md:leading-10 md:text-2xl ">
                        <span className="font-semibold">  Renners Investment Limited was incorporated in November 2020 to provide the finest financial services & products that put the customer first.</span> Our premium aim is to bridge the growing wide gap that exists among potential customers, Nigeria growing economy and effective financial solutions. We provide lending services that give new direction and solutions to the Nigerian market.
                        <p className="mt-8">
                            We are a customer-focused company established to solve financial challenges, three Ps define our services: PROMPTNESS, PEOPLE and POSSIBILITIES. Our lending decision is faster because we believe in our customers and the possibilities
                        </p>
                        <p className="mt-8">
                            As a lending company, Renners has thrived within the past years by registering its footprint in the financial market with a remarkable presence in various sectors such as Manufacturing, Logistics, Trading, Oil & Gas, telecommunication, Education etc.
                        </p>
                    </p>
                </div>
            </div>
            <div className="pt-8 md:pt-24 pb-8 md:pb-36 container px-4 md:px-36 mx-auto">
                <div className="flex md:flex-row flex-col bg-[#F1F1F1] rounded-xl p-8 md:gap-20 gap-8">
                    <div className="h-full flex flex-col text-left">
                        <h4 className="text-red-500  text-2xl">
                            Our Vision
                        </h4>
                        <div className="flex-1 py-3">
                            <p className="text-base md:text-xl !leading-loose">
                                To be the preferred lending company,
                                providing quality financial services for
                                our customers.
                            </p>
                        </div>
                    </div>
                    <div className="h-full flex flex-col text-left">
                        <h4 className="text-red-500  text-2xl">
                            Our Core Values
                        </h4>
                        <div className="flex-1 py-3">
                            <p className="text-base md:text-xl !leading-loose">
                                We are a big advocate of Simplicity, Timeliness, Professionalism, Integrity and Excellence, using the appropriate technology to drive our system.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="md:mt-24 mt-12">
                    <div className="text-center self-end">
                        <h4 className="text-lg font-semibold">
                            Want to join us at Renners?
                        </h4>
                        <Link to="/career" className="inline-block mt-6 rounded-lg bg-purple px-8 py-4 font-light text-white">
                            <div className="flex items-center gap-2">
                                <span>
                                    View career openings
                                </span>
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 13L7 7L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>

                        </Link>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default AboutPage;