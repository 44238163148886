import { Link } from "react-router-dom";
import { getIcon, getImage, getLogo } from "../../utils";

const TermsAndConditionsPage = () => {
    return (
        <div>
            <div className="py-12 px-6 md:px-20">
                <h1 className="text-3xl text-left font-bold text-purple">
                    Terms and Conditions
                </h1>
                <div className="mt-8 text-left">
                    <p>
                        Welcome to Renners Investment Limited, a fintech platform in the business of providing micro lending to individuals and businesses (which shall hereinafter, together with its subdomains, be referred to as the "Site" or the “Website” or the “Platform”). Please read the following terms of use (the “Terms”) carefully before using this Site so that you are aware of your legal rights and obligations with respect to Renners Investment Limited ("Renners", "We", "Us", “the Company” or "Our”). By accessing or using the Site, you expressly acknowledge and agree that you are entering a legal agreement with Renners and have understood and agree to abide with, and agree to be legally bound by, these Terms, as well as the Privacy Policy . Where you are not in agreement or acceptance with these Terms, please do not use the Site and the Service and/or discontinue access to the Site and the Service.
                    </p>
                    <p className="mt-4">
                        By completing the registration process or by accessing the Site in any manner or requesting the use of the Service through any means, you are indicating your consent to be bound by these Terms as an agreement.
                    </p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        Review of Terms of Use
                    </h3>

                    <p className="mt-4"> We may, at our sole discretion, review, revise and/or update this Terms of Use and the Site at any time. We therefore advise that you read through this Terms of Use periodically.</p>

                    <p className="mt-4"> We reserve the right to withdraw or amend this Site, and any service or material we provide on the Site, in our sole discretion without notice. We will not be liable, if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>

                    <p className="mt-4">You are responsible for both:</p>
                    <ul className="pl-6 my-4">
                        <li>  • Making all arrangements necessary for you to have access to the Website.</li>
                        <li>  • Ensuring that all persons who access the Website through your internet connection are aware of these Terms and comply with them</li>
                    </ul>
                    <p className="mt-4"> To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current and complete. You agree that all information you provide during your use of this Website or otherwise, including, but not limited to the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        1.	AVAILABLE CONTENT AND USE
                    </h3>
                    <ul className="pl-6 my-4">
                        <li>  a. Content Description:</li>

                    </ul>
                    <p className="mt-4">The Platform contains variety of contents, including but not limited to text, data, files, documents, software, scripts, layout, design, function, aesthetics, graphics, images, audio, videos, audiovisual combinations, interactive features and any other materials that you may view, access or download (but only as expressly permitted in paragraph C below) on the Platform.  https://rennersng.com/privacy-policy</p>
                    <ul className="pl-6 my-4">
                        <li>  b. Proprietary Rights</li>
                    </ul>
                    <p className="mt-4">
                        All the contents of the Platform, whether publicly posted or privately transmitted, as well as all derivative works thereof, are owned by Renners, its affiliates and where applicable, to third parties who have licensed such rights to Renners (“Licensors”). Hence, the Platform is protected by copyright, trademark, and other applicable intellectual property rights/laws. Except as specifically permitted herein, you shall not copy, reproduce, republish, upload, post, transmit, modify or distribute the Platform or any part of it in any way, including by e-mail or other electronic means, without the prior consent of Renners or its Licensors.
                    </p>
                    <ul className="pl-6 my-4">
                        <li>
                            c.	Restrictions on use of the Platform
                        </li>
                    </ul>
                    <ul className="pl-6 my-4">

                        <li> i.	modify or use the Content on any web Platform or networked computer environment or using the Content for any purpose other than personal, non-commercial use, without the consent of Renners or its licensors first had and obtained, where applicable. Such use or modification is a violation of the copyright, trademark, and other proprietary rights in the Content.</li>

                        <li> ii.	 use our Platform in any way or take any action that causes or may cause damage to the Platform or impairment of the performance, availability or accessibility of the Platform;</li>

                        <li> iii.	use our Platform in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity or in any way that breaches any applicable law or regulations;</li>

                        <li> iv.	circumvent, disable or otherwise interfere with security-related features of the Services; including security features that prevent or restrict the use or copying of any content.</li>

                        <li> v.	use data collected from our Platform for any direct marketing activity (including without limitation email marketing, SMS marketing, telemarketing or direct mailing).</li>

                        <li> vi.	alter, remove, or falsify any attributions or other proprietary designations of origin or source of any Content appearing on the Platform.</li>

                        <li> vii.	use the Platform in any way to create liability, or in any way that causes us to lose the services of our Internet Service Providers or other suppliers.</li>

                        <li> viii.	download any content on the Platform unless you see a “download” or similar link displayed by us on the Platform for such content.</li>

                        <li> ix.	upload, post, email, transmit or otherwise make available on the Site, any content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party;</li>
                        <li> x.	upload, post, email, transmit or otherwise make available on the Site, any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.</li>

                        <li> xi.	disrupt the normal flow of or otherwise act in a manner that negatively affects other user’s ability to engage in real-time exchanges.</li>

                        <li> xii.	intentionally or unintentionally violate any applicable local, state, national or international laws and any regulations having the force of law.</li>

                        <li> xiii.	refrain from creating multiple accounts for the purpose of accessing the Site and using the features by artificially renewing any trial period offered by Renners.</li>

                    </ul>
                    <p className="mt-4"> You further agree that: </p>
                    <ul className="pl-6 my-4">
                        <li> i. you are responsible for your own conduct while using the Platform or Services and for any consequences thereof;</li>

                        <li> ii.	You shall use this Platform and all Services on the Platform only for purposes that are legal, proper and in accordance with this Terms of Use, the Privacy Policy, and any applicable law, rules or regulations, any consumer protection, unfair competition, and anti-discrimination laws or regulations and any applicable foreign laws).</li>

                        <li> iii.	You are solely responsible for your interactions with other users of the Platform (“Users”) both within the Platform and outside of the Platform. Renners expressly disclaims any responsibility for interactions, loss or liabilities between users or between you and any third party outside of the Platform.</li>
                    </ul>
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        2.	ACCOUNT CREATION & Personal Information
                    </h3>
                    <p className="mt-4">
                        Before using the Services, you must: (a) accept and agree to these Terms and our Privacy Policy; (b) register with us on the Site and create an account (“Account”); (c) be at least 18 years of age (or older if you reside in a state where the majority age is older). When you register for an Account, you may be required to provide information that will allow us to verify your identity and/or the information of the business entity with which you are associated (“User Information”), including but not limited to:
                    </p>
                    <p className="mt-4"> 2.1 To meet our Know Your Customer (“KYC”) and Anti-Money Laundering (“AML”) obligations, we may require you to provide any or all the following:</p>
                    <p className="mt-4"> 2.1.1 your full name;</p>
                    <p className="mt-4"> 2.1.2 your date of birth;</p>
                    <p className="mt-4"> 2.1.3 your Bank Verification Number (“BVN”);</p>
                    <p className="mt-4"> 2.1.4 your current residential address;</p>
                    <p className="mt-4"> 2.1.5 your picture;</p>
                    <p className="mt-4"> 2.1.6 copy of a valid Government issued ID (national ID, international passport, permanent voter’s card or driver’s license);</p>
                    <p className="mt-4"> 2.1.7 a copy of recent utility bill, bank statement, affidavit, or another bill, dated within three months of our request, which carries your name and address;</p>
                    <p className="mt-4"> 2.1.8 any other information or document as we may require for our internal risk assessment.</p>
                    <p className="mt-4"> 2.2 You warrant that all information and documentation provided to us are true, correct, and accurate. You also undertake to notify us of any changes to the information or documentation which you have provided.</p>
                    <p className="mt-4"> 2.3 You hereby agree and authorise Renners to verify information provided by you against the information held by any third party (including official databases) such as, NIBBS, Payment System Providers any other information bank available to Renners.</p>
                    <p className="mt-4"> 2.4 The information that Renners may verify against the information which you have provided to us include (without limitation): your name, phone number, date of birth, address, age, Identification Number (“ID”) or Passport Number and such other information that will enable Renners to identify you and comply with the regulatory “Know Your Customer” requirements (together the “Personal Information”).</p>
                    <p className="mt-4"> 2.5 You hereby agree and authorise Renners to collect and verify information including, but not limited to, data relating to your phone (including, without limitation, your phone’s history) from your Equipment, from any SMS sent to you or by you, from any 3rd party applications, and such other information as Renners shall require for purposes of providing you the Services (the “Relevant Information”). In case you are nominating a person as your guarantor, you are also confirming that you have sought approval from the named person for the same, and that they are willing to act as a guarantor for this loan facility.</p>
                    <p className="mt-4"> 2.6 You hereby consent to Renners verifying the Personal Information and the Relevant Information and using the Personal Information and the Relevant Information to the extent necessary in Renners opinion for the provision of the Services.</p>
                    <p className="mt-4"> 2.7 You hereby agree and authorise Renners to obtain and procure your Personal Information and Relevant Information from relevant sources (private and official databases) and you further agree and consent to the disclosure and provision of such Personal Information by us or our Third Party Partners.</p>
                    <p className="mt-4"> 2.8 You agree to indemnify and hold Renners and the Third-Party Partners harmless with respect to any claims, losses, liabilities and expenses (including legal fees and expenses) that may arise as a result of the disclosure and reliance on such Personal Information and/or Relevant Information.</p>
                    <p className="mt-4"> 2.9 Renners reserves the right to request for further information from you pertaining to your use of the Services at any time. Failure to provide such information within the time required by Renners may result in Renners declining to accept your application for an Account or access to the Services.</p>
                    <p className="mt-4"> 2.10 You acknowledge and agree that when Renners or our third-party service providers access and retrieve information from such third-party websites, Renners and our third-party service providers are acting as your agent, and not the agent of, or on behalf of the third party.</p>
                    <p className="mt-4"> 2.11 You consent to indemnify Renners against any liability resulting from Erroneous transfer.</p>
                    <p className="mt-4"> 2.12 You consent that Renners can deduct funds from customer's account when such funds have been established as erroneous or fraudulent in nature.</p>
                    <p className="mt-4"> 2.13 Renners advises its customers not to give their financial details, such as BVN (Bank Verification Numbers), OTP (One Time Password) and other sensitive financial information to anyone or third party. Renners will not be liable if such details are compromised.</p>
                    <p className="mt-4">
                        You may also be required to provide additional information or documentation to allow us to verify your identity, your associated business entity, and/or your Account information. We may also be required to verify the information you provide against third-party databases or other sources and you authorize us to make such inquiries.
                    </p>

                    <p className="mt-4"> The availability of any feature and function on the Site to any User may be conditional on verification of User’s identity.</p>

                    <p className="mt-4"> In addition, during the registration process, you may be asked to enter your user credentials for your online accounts with third-party financial institutions, such as your bank, so that we may review your financial transaction information. You expressly authorize us to access these accounts and view such information solely to perform the Services. The Services will provide metadata and other context for the reporting of payments initiated via Services API or web platform, available via API, web, or CSV download. You are granted a limited, non-exclusive, non-transferable, non-sublicensable, revocable right to use such reporting for your internal business analysis purposes only, and for no other purpose.</p>

                    <p className="mt-4"> Upon your registration on the Site, you will obtain your login details as provided by you to enable you to access the services. The Site is accessible by you by entering the Username and Password (“Login Details”).</p>

                    <p className="mt-4"> Renners reserves the right to change, upgrade, modify, limit or suspend the Service or any of its related functionalities or applications at any time temporarily or permanently without prior notice. Renners further reserves the right to introduce new features, functionalities, applications or conditions to the Service or to future versions of the Service. All new features, functionalities, applications, conditions, modifications, upgrades and alterations shall be governed by this Terms, unless otherwise stated by Renners.</p>

                    <p className="mt-4"> We reserve the right at any time to suspend without notice your access to and/or use of the Site to enable us to carry out essential emergency and/or urgent maintenance. We will use reasonable commercial endeavours to provide you with reasonable advance notice of any scheduled non-emergency maintenance. Scheduled non-emergency maintenance will be performed outside of business hours to the extent reasonably practicable. We will use commercially reasonable endeavours to notify you of any unplanned downtime of the Site and to resolve the issue as soon as reasonably practicable.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        3. USER’S RESPONSIBILITIES
                    </h3>
                    <p className="mt-4"> 3.1    The User hereby represents, warrants and agrees to (a) provide true, accurate, current and complete information about itself and its business references as may be required by Renners and (b) maintain and promptly amend all information to keep it true, accurate, current and complete. The User must update Renners from time to time of any material change on any of the information provided upon signing up by updating their account.</p>

                    <p className="mt-4"> 3.2.  The User hereby grants an irrevocable, perpetual, worldwide and royalty-free, sub-licensable (through multiple tiers) license to Renners to display and use all information provided by such User in accordance with the purposes set forth in this Terms and to exercise the copyright, publicity, and database rights you have in such material or information, in any media now known or not currently known.</p>

                    <p className="mt-4"> 3.3   	Renners does not endorse, verify or otherwise certify the contents of any comments or other material or information made by any User. The User is solely responsible for the contents of their communications and may be held legally liable or accountable for the content of their comments or other material or information.</p>

                    <p className="mt-4"> 3.4	The User acknowledges and agrees that it has obtained all necessary third-party licenses and permissions and shall be solely responsible for ensuring that any material or information it posts on the Site or provides to Renners or authorizes Renners to display does not violate the copyright, patent, trademark, trade secrets or any other personal or proprietary rights of any third party or is posted with the permission of the owner(s) of such rights. The User further represents, warrants and agrees that it has the right and authority to sell, distribute or offer to sell or distribute the products described in the material or information it posts on the Site,aapplication or provides to Renners or authorizes Renners to display.</p>

                    <p className="mt-4"> 3.5. 	Renners has the right to suspend or terminate the Service without any compensation and restrict or refuse any and all current or future use of the Service or any other services that may be provided by Renners where a User breached the representations, warranties and covenants in this Terms.</p>

                    <p className="mt-4"> 3.6.   	The User agrees to indemnify Renners, its employees, agents, and representatives and to hold them harmless, from any and all losses, claims and liabilities (including legal costs on a full indemnity basis) which may arise from its submissions, posting of materials or deletion thereof, from such User’s use of the Service or from such User’s breach of this Terms. The User further agrees that Renners is not responsible and shall have no liability to it or anyone else for any material posted by such User or third parties, including fraudulent, untrue, misleading, inaccurate, defamatory, offensive, or illicit material and that the risk of damage from such material rests entirely with each User. Renners reserves the right; at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by the User, in which event the User shall cooperate with Renners in asserting any available defenses.</p>

                    <p className="mt-4"> 3.7.	Reliance on Information Posted</p>

                    <p className="mt-4"> The information presented on or through the Website is made available solely for educational purposes. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Renners, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Renners. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>

                    <p className="mt-4"> 3.8. 	Information About You and Your Visits to the Website /renners mobile apps</p>

                    <p className="mt-4"> All information we collect on this Website and  is subject to our Privacy Policy. By using the Website/renners mobile apps, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        4. CONFIDENTIALITY
                    </h3>
                    <p className="mt-4"> 4.1.	You undertake that all communication, content, intellectual property or other information, and materials on the Platform, either marked ‘confidential’ or is by its nature intended to be for your knowledge alone, shall be kept confidential unless or until you can reasonably demonstrate that such communication, information and material is, or part of it is, in the public domain through no fault of yours. Furthermore, any communication, content, intellectual property or other information, and materials you obtain in terms of or arising from the use of this Platform shall be treated as confidential and shall not be divulged or permitted to be divulged to third parties, without our prior written consent.</p>

                    <p className="mt-4"> 4.2.	Please note that all obligations relating to Confidential Information under this Terms of Use will continue after termination of the Terms of Use and termination of access rights hereunder.</p>
                    <p className="mt-4"> Renners Loans</p>

                    <p className="mt-4"> These Terms and Conditions apply to and regulate the provision of credit facilities by the Renners Investment Limited through its service “Renners” to the Borrower herein. These Terms and Conditions constitute the Lender’s offer and sets out the terms governing this Agreement.</p>
                    <p className="mt-4"> Renners mentions below relates to the Renners app, a service provided by the Renners .</p>
                    <p className="mt-4"> Renners is an open-end credit plan offered by the Lender and by accepting, an account is set up with the Renners app and you agree that you have read the Terms and Conditions. You authorize the Lender to review your credit report and you understand that this account is subject to transaction fees and default fees and is governed by the Laws of the Federal Republic of Nigeria.</p>
                    <p className="mt-4"> You will be asked to provide information (such as your date of birth and your Bank Verification Number), when you apply for a loan. This information is used for verification purposes.</p>
                    <p className="mt-4"> HOW TO USE RENNERS LOAN ACCOUNT</p>
                    <p className="mt-4"> This is an open-end credit account where you access direct loan or credit facility using Renners, while you repay the loan or credit facility on a future date via cash transfer or electronic repayment (whether manual or automatic) from your debit/credit card on the Payment Due Date at a flat interest on the loan of up to 1% daily.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        5. DEFINITIONS
                    </h3>
                    <p className="mt-4">  In This Agreement, The Terms: “You”, “Your”, “Customer”, And “Borrower” Mean the Person Who Applied for This Account and Agrees to This Agreement While “We”, “Us”, “Our” And “Lender” Shall Mean Renners , And Following an Assignment, Any Person, Company or Bank To Whom The Rights And/or Obligations of The Lender Have Been Assigned</p>
                    <p className="mt-4">  5.1. “Account” Means the Borrower’s Account with The Lender</p>
                    <p className="mt-4">  5.2. “Disbursement Date” Means the Date the Lender Actually Advanced the Loan to The Borrower</p>
                    <p className="mt-4">  5.3. “Payment Due Date” Means the Date When the Instalment Should Be Repaid</p>
                    <p className="mt-4">  5.4. “Credit Limit” Means the Maximum Credit Available to The Borrower on Opening the Account with The Lender</p>
                    <p className="mt-4">  5.5. “Loan” Means the Amount Advanced to The Borrower by The Lender, Which Shall Be No Less Than N1,000.00 (One Thousand Naira Only)</p>
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        6. LOAN FEES
                    </h3>
                    <p className="mt-4"> 6.1. The loan Fees payable by you in relation to any loan shall be displayed on the app.</p>
                    <p className="mt-4"> 6.2. Failure to make repayments will attract a late fee of a maximum of 1% per month of delay. Interest on the unpaid amount will continue to be accrued post the due-date at the rate quoted at the time of loan disbursal (up to a maximum of 30%)</p>
                    <p className="mt-4"> 6.3. The loan fee may be increased or decreased from time to time by the Lender. Such change in loan fee will take effect on the Borrower’s account following a minimum of 20 days written notice.</p>
                    <p className="mt-4"> 6.4. In the event that the borrower wishes to liquidate the loan facility before the closure date (final due date), they may contact Renners at Support@rennersng.com to understand the reduction in total amount to repay, given that the loan is being terminated early.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        7. Borrower’s Obligations
                    </h3>
                    <p className="mt-4">   7.1. To pay to us, the loan sum, including any convenience, default or penalty fees, flat interest rate, and other amounts due to the Lender charged to this Account. Borrower promises to pay these amounts as agreed in this Agreement on or before the Payment Due Date.</p>
                    <p className="mt-4">   7.2. The Lender reserves the right to presume that the Borrower has authorized any loan application made in the name of the Borrower using this Account. The Borrower will be deemed responsible for any unauthorized application using this Account unless the Borrower notifies the Lender, in writing, of imminent fraud by another person on the Borrower’s account within 24 hours of such fraud.</p>
                    <p className="mt-4">   7.3. To repay the loan given to you and/or to someone you authorized to use this Account via direct cash transfer to a bank account listed by the Lender, an electronic debit from the card/account you provided on the due date, or through an acceptable electronic channel.</p>
                    <p className="mt-4">   7.4. The Lender reserves the right to accept early repayment before the Payment Due Date, provided the Borrower has given adequate notice and repays full loan along with the flat interest rate. Loan repayment via electronic debit card may be affected by the Lender in the way and manner hereinafter specified:</p>
                    <p className="mt-4">   7.4.1. The Lender shall charge a one-time processing fee the first time a Customer sets up a new debit card on the Account. In the event the card is expired or about to expire, you will have to obtain a renewed card from your bank or provide us with another personal debit card.</p>
                    <p className="mt-4">   7.4.2. The Lender shall deduct from the card setup on the repayment due date. We will never make any transactions on the card outside of the scheduled loan repayments. In the event of a double repayment initiated in error by you, we will on a best effort basis process a refund within a reasonable time.</p>
                    <p className="mt-4">   7.4.3. You hereby indemnify us against any and all losses, liabilities or damages that may arise in the event that you use a third party’s card on your Account without the consent of such third party.</p>
                    <p className="mt-4">   7.4.4. In the event of a default or insufficiency of funds in your bank account, you hereby authorize us to charge any other cards you may have on your Account or profile other than the primary card listed on an ongoing basis until the debt is settled.</p>
                    <p className="mt-4">   7.4.5. You understand and agree that we do not store card details on our platform thus card details shall be processed through a secure payment gateway that is PCI DSS compliant.</p>
                    <p className="mt-4">   7.5. You agree to give The Lender authentic and up-to-date personal, social media, phone and financial records about you that we may reasonably request and analyze, from time to time.</p>
                    <p className="mt-4">   7.6. The Borrower shall indemnify and hold harmless the Lender and its representatives (each, an “Indemnified Person”) from and against any and all suits, actions, proceedings, claims, damages, losses, liabilities and expenses (including, without limitation, counsel’s fees and disbursements and other costs of investigation or defense, including those incurred upon any appeal) which may be instituted or asserted against or incurred by any Indemnified Person as the result or arising out of:</p>
                    <p className="mt-4">   7.6.1. credit having been extended, suspended, or terminated under this Agreement, or</p>
                    <p className="mt-4">   7.6.2 any loan documents and the administration of such credit and in connection with or arising out of the transactions contemplated under this Agreement or any loan document and any actions or failures to act in connection therewith and any legal costs and expenses arising out of or incurred in connection with disputes between or among any parties to this Agreement or any loan document (collectively, “Indemnified Liabilities”);</p>
                    <p className="mt-4">   7.6.3. provided that the Borrower shall not be liable for any indemnification to an Indemnified Person to the extent that any such suit, action, proceeding, claim, damage, loss, liability or expense results solely from that Indemnified Person’s gross negligence or wilful misconduct, as finally determined by a court of competent jurisdiction.</p>
                    <p className="mt-4">   7.7. The Borrower also agrees:</p>
                    <p className="mt-4">   7.7.1. Not to give us false information or signatures, electronic or otherwise, at any time.</p>
                    <p className="mt-4">   7.7.2. To pay a Late Fee or penalty fee as may be provided in this terms and conditions.</p>
                    <p className="mt-4">   7.7.3. To make all payments via direct debit on cards or by electronic funds transfer as stated in the application form.</p>
                    <p className="mt-4">   7.7.4. To promptly notify us if you change your name, your mailing address, your e-mail address or your telephone number.</p>
                    <p className="mt-4">   7.7.5. To honor any other promises that you make in this Agreement.</p>
                    <p className="mt-4">   7.7.6. not to accept this Account unless you are of legal age and have the capacity to enter into a valid contract.</p>
                    <p className="mt-4">   7.7.7. Not to use Renners only for any act of illegality or criminality as Renners  will not be criminally culpable for any illegality committed by you.</p>
                    <p className="mt-4">   7.7.8. To give the Lender legal authority to deduct full repayment before due date where it is established that the loan was obtained fraudulently.</p>
                    <p className="mt-4">   7.7.9. To fill out the application form (as provided) with accurate information and details as required.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        8. Lender's Obligations
                    </h3>
                    <p className="mt-4"> 8.1. To make available the loan/credit facility to the Borrower of a figure not less than N1,000.00 (One Thousand Naira only)</p>
                    <p className="mt-4"> 8.2. To perform above upon confirmation of the Borrower’s identity and personal information</p>
                    <p className="mt-4"> 8.3. To demand repayment for the Borrower as and when due</p>
                    <p className="mt-4"> 8.4. To conduct investigations on the Borrower prior to opening an account for the Borrower</p>
                    <p className="mt-4"> 8.5. To use all reasonable and legitimate means to collect the amount extended to the Borrower, the default fee, the transaction fees and any other penalty fee imposed on the Borrower as a result of the loan.</p>
                    <p className="mt-4"> 8.6. Not to store or save Borrower’s debit or credit card details given by the Borrower in the application form.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        9. Credit Reference
                    </h3>
                    <p className="mt-4"> 9.1 The Lender or its duly authorized representatives/agents will utilize dedicated Credit Agencies for a credit report on the Borrower in considering any application for credit.</p>
                    <p className="mt-4"> 9.1.1. The Borrower authorizes The Lender to access any information available to The Lender as provided by the Credit Agency.</p>
                    <p className="mt-4"> 9.1.2. The Borrower also agrees that the Borrower’s details (save the card details) and the loan application decision may be registered with the Credit Agency.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        10. Notices
                    </h3>
                    <p className="mt-4"> 10.1. The Borrower agrees that The Lender may communicate with them by sending notices, messages, alerts and statements in relation to this Agreement in the following manner:</p>
                    <p className="mt-4"> 10.1.1. To the most recent physical address The Lender holds for the Borrower on file</p>
                    <p className="mt-4"> 10.1.2. By delivery to any email address provided during the application process.</p>
                    <p className="mt-4"> 10.1.3. By delivery of an SMS to any mobile telephone number the Borrower has provided to The Lender.</p>
                    <p className="mt-4"> 10.1.4. By posting such notice on the Lender’s website.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        11. Event of Default
                    </h3>
                    <p className="mt-4"> Default in terms of this Agreement will occur if:</p>
                    <p className="mt-4"> 11.1. The Borrower fails to make any scheduled repayment in full on or before the payment due date in accordance with the repayment plan given to the Borrower.</p>
                    <p className="mt-4"> 11.2. Any representation/information, warranty or assurance made or given by the Borrower in connection with the application for this loan or any information or documentation supplied by the Borrower is later discovered to be materially incorrect; or</p>
                    <p className="mt-4"> 11.3. The Borrower does anything which may prejudice the Lender’s rights in terms of this Agreement or causes the Lender to suffer any loss or damage.</p>
                    <p className="mt-4"> 11.4. In the event of any default by the Borrower subject to clause 11.0. above –</p>
                    <p className="mt-4"> 11.4.1. The Lender reserves the right to assign its right, title and interest under the Agreement to an external Collections Agency who will take all reasonable steps to collect the outstanding loan amount.</p>
                    <p className="mt-4"> 11.4.2. The Lender also reserves the right to institute legal proceedings against the defaulting Borrower and is under no obligation to inform the Borrower before such proceedings commence.</p>
                    <p className="mt-4"> 11.4.3. The Borrower shall indemnify and hold harmless the Lender and its representatives (each, an “Indemnified Person”) from and against any and all suits, actions, proceedings, claims, damages, losses, liabilities and expenses (including, without limitation, counsel’s fees and disbursements and other costs of investigation or defence, including those incurred upon any appeal) which may be instituted or asserted against or incurred by any Indemnified Person as the result or arising out of:</p>
                    <p className="mt-4"> 11.4.3.1. credit having been extended, suspended or terminated under this Agreement or</p>
                    <p className="mt-4"> 11.4.3.2. any loan documents and the administration of such credit and in connection with or arising out of the transactions contemplated under this Agreement or any loan document and any actions or failures to act in connection therewith and any legal costs and expenses arising out of or</p>
                    <p className="mt-4"> 11.4.3.3. incurred in connection with disputes between or among any parties to this Agreement or any loan document (collectively, “Indemnified Liabilities”);</p>
                    <p className="mt-4"> 11.4.4. provided that the Borrower shall not be liable for any indemnification to an Indemnified Person to the extent that any such suit, action, proceeding, claim, damage, loss, liability or expense results solely from that Indemnified Person’s gross negligence or wilful misconduct, as finally determined by a court of competent jurisdiction.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        12. DISCLAIMER OF WARRANTIES
                    </h3>
                    <p className="mt-4"> You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
                    <p className="mt-4"> YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER RENNERS NOR ANY PERSON ASSOCIATED WITH RENNERS MAKE ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                    <p className="mt-4"> RENNERS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
                    <p className="mt-4"> THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        13. LIMITATION OF LIABILITY
                    </h3>
                    <p className="mt-4"> 13.1. 	TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL RENNERS, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>

                    <p className="mt-4"> THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        14.      FINANCIAL PARTNERS
                    </h3>

                    <p className="mt-4">
                        At Renners, we partner with other financial/payment service providers in providing our Services to you. To enable you make payments on/through the Platform, we may share your information with these financial partners. You therefore authorize us to share your identity and banking information with partner financial institutions in respect of our Services. Renners shall not be liable for any default, negligence or breach of the financial partners.
                    </p>

                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        15. INDEMNITY
                    </h3>
                    <p className="mt-4">
                        You agree to defend, indemnify and hold harmless Renners, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Website, including, but not limited to, your User Obligations, any use of the Website's content, services and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.
                    </p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        16. INTELLECTUAL PROPERTY RIGHTS
                    </h3>

                    <p className="mt-4"> The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Renners, its licensors or other providers of such material and are protected under Nigerian copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>

                    <p className="mt-4"> These Terms permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website, without Renners’s authorized written permission and/or the express, authorized written permission of the copyright owner.</p>

                    <p className="mt-4"> No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by Renners. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms and may violate copyright, trademark and other laws.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        17. BREACH OF TERMS AND CONDITIONS
                    </h3>
                    <p className="mt-4"> 17.1.Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we shall:</p>
                    <ul className="pl-6 my-4">
                        <li> i.	send you one or more formal warnings in writing to this effect;</li>

                        <li> ii.	temporarily suspend your access to our Platform;</li>

                        <li> iii.	permanently prohibit you from accessing our Platform;</li>

                        <li> iv.	block your access to the Platform;</li>

                        <li> v.	commence legal action against you, whether for breach of contract or otherwise; and/or</li>

                        <li> vi.	suspend or delete your account on our Platform.</li>

                    </ul>
                    <p className="mt-4"> Where we suspend or prohibit or block your access to our Platform or a part of our Platform, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation, creating and/or using a different account).</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        18. BREACH OF TERMS AND CONDITIONS
                    </h3>
                    <p className="mt-4"> No waiver, by Renners, of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Renners to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.</p>
                    <p className="mt-4"> If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        19. 	GOVERNING LAW AND DISPUTE RESOLUTION
                    </h3>
                    <p className="mt-4"> 19.1.This Terms of Use and all disputes and matters arising from the Platform (or its use) shall be governed by the laws of the Federal Republic of Nigeria.</p>
                    <p className="mt-4"> 19.2. In the event of a controversy, claim or dispute arising out of or relating to this Terms of Use, the Parties shall attempt in good faith to resolve such controversy, claim or dispute promptly by negotiation between the parties or their authorized representatives. You shall, before exploring any other remedy in law, notify Renners of the dispute or complaint through the contact details below. If parties are unable to resolve the controversy, claim or dispute, the parties shall be at liberty to explore any other dispute resolution mechanism known to Law including mediation, arbitration or litigation. </p>
                    <h3 className="text-2xl text-purple font-bold mt-4 uppercase">
                        20 GENERAL
                    </h3>
                    <ul className="pl-6 my-4">
                        <li> ●	you breach any provision of these Terms;</li>
                        <li> ●	the Company is required to do so under law;</li>
                        <li> ●	liquidation;</li>
                        <li> ●	the Company chooses to discontinue the Service being offered or discontinue to operate the Platform.</li>
                    </ul>

                    <p className="mt-4"> The Company reserves its right to enforce the obligations contained herein, even when you have uninstalled the Platform or even after termination of these Terms and Conditions, until all your obligations are fulfilled.</p>

                    <p className="mt-4"> Upon termination of these Terms, the rights granted to you under these Terms shall cease to exist. Notwithstanding anything contained in these Terms or otherwise, the termination of these Terms for any reason whatsoever, shall not affect your obligations, including but not limited to repayment of any outstanding amount(s).</p>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage;