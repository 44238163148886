import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRoutes from './Routes';
import { Outlet } from 'react-router';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import ScrollListener from './Components/ScrollListener';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className=''>
      <ToastContainer />
      <ScrollListener />
      <Navbar />
      <main className="App overflow-x-hidden relative">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
