import { motion, useCycle, Variants } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getImage } from "../../utils";

interface CardProps {
    visibleId: string;
    onClick: (id: string) => void;
}
const Gallery1: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "1"

    return (
        <div className={`relative rounded`}>
            <div className="relative rounded">
                <img src={getImage("gallery-1.png")} alt="" />
                <div className={`absolute bottom-0 left-0 p-3`}>

                    <button onClick={() => {
                        onClick(id)
                    }} className={`text-red-600 bg-white text-xs px-2 py-1 rounded ${visibleId == id ? "invisible" : ""}`}>
                        Read more
                    </button>
                </div>
            </div>
            {visibleId == id ? <div className="w-full h-full bg-black bg-opacity-90 absolute top-0 left-0 z-10 rounded">
                <div className="text-white text-xs px-8 py-8 leading-loose">
                    Worry less about how to sustain and move your business to the next milestone when you have  Renners to rely on.
                    We provide cash funding for small and medium-scale businesses, salary earners and Individual projects.
                </div>
                <div className={`absolute bottom-0 left-0 p-3`}>
                    <button onClick={() => {
                        onClick(id)
                    }} className="text-red-600 bg-white text-xs px-2 py-1 rounded">
                        Close
                    </button>
                </div>
            </div> : <></>}
        </div>
    )
}
const Gallery2: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "2"

    return (
        <div className={`relative rounded`}>
            <div className="relative rounded">
                <img src={getImage("gallery-2.png")} alt="" />
                <div className={`absolute bottom-0 left-0 p-3`}>

                    <button onClick={() => {
                        onClick(id)
                    }} className={`text-red-600 bg-white text-xs px-2 py-1 rounded ${visibleId == id ? "invisible" : ""}`}>
                        Read more
                    </button>
                </div>
            </div>
            {visibleId == id ? <div className="w-full h-full bg-black bg-opacity-90 absolute top-0 left-0 z-10 rounded">
                <div className="text-white text-xs px-8 py-8 leading-loose">
                    Worry less about how to sustain and move your business to the next milestone when you have  Renners to rely on.
                    We provide cash funding for small and medium-scale businesses, salary earners and Individual projects.
                </div>
                <div className={`absolute bottom-0 left-0 p-3`}>
                    <button onClick={() => {
                        onClick(id)
                    }} className="text-red-600 bg-white text-xs px-2 py-1 rounded">
                        Close
                    </button>
                </div>
            </div> : <></>}
        </div>
    )
}
const Gallery3: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "3"

    return (
        <div className={`relative rounded`}>
            <div className="relative rounded">
                <img src={getImage("gallery-3.png")} alt="" />
                <div className={`absolute bottom-0 left-0 p-3`}>

                    <button onClick={() => {
                        onClick(id)
                    }} className={`text-red-600 bg-white text-xs px-2 py-1 rounded ${visibleId == id ? "invisible" : ""}`}>
                        Read more
                    </button>
                </div>
            </div>
            {visibleId == id ? <div className="w-full h-full bg-black bg-opacity-90 absolute top-0 left-0 z-10 rounded">
                <div className="text-white text-xs px-8 py-8 leading-loose">
                    Worry less about how to sustain and move your business to the next milestone when you have  Renners to rely on.
                    We provide cash funding for small and medium-scale businesses, salary earners and Individual projects.
                </div>
                <div className={`absolute bottom-0 left-0 p-3`}>
                    <button onClick={() => {
                        onClick(id)
                    }} className="text-red-600 bg-white text-xs px-2 py-1 rounded">
                        Close
                    </button>
                </div>
            </div> : <></>}
        </div>
    )
}
const Gallery4: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "4"

    return (
        <div className={`relative rounded`}>
            <div className="relative rounded">
                <img src={getImage("gallery-4.png")} alt="" />
                <div className={`absolute bottom-0 left-0 p-3`}>

                    <button onClick={() => {
                        onClick(id)
                    }} className={`text-red-600 bg-white text-xs px-2 py-1 rounded ${visibleId == id ? "invisible" : ""}`}>
                        Read more
                    </button>
                </div>
            </div>
            {visibleId == id ? <div className="w-full h-full bg-black bg-opacity-90 absolute top-0 left-0 z-10 rounded">
                <div className="text-white text-xs px-8 py-8 leading-loose">
                    Worry less about how to sustain and move your business to the next milestone when you have  Renners to rely on.
                    We provide cash funding for small and medium-scale businesses, salary earners and Individual projects.
                </div>
                <div className={`absolute bottom-0 left-0 p-3`}>
                    <button onClick={() => {
                        onClick(id)
                    }} className="text-red-600 bg-white text-xs px-2 py-1 rounded">
                        Close
                    </button>
                </div>
            </div> : <></>}
        </div>
    )
}

const MediaPage = () => {
    const [visibleId, setVisibleId] = useState("");

    return (
        <div>
            <div className="md:py-24 py-12 container md:px-24">
                <div className="text-center">
                    <div className="flex gap-6 md:gap-16 justify-center">
                        <div className="md:text-xl text-purple  font-bold">
                            Events
                        </div>
                        <div className="md:text-xl text-purple  font-bold">
                            Workspace
                        </div>
                        <div className="md:text-xl text-purple  font-bold">
                            Others
                        </div>
                    </div>


                </div>

                <div className="mt-12  mx-auto  px-4 text-left  md:text-lg container">
                    <div className="relative">
                        <div className="mt-6 md:mt-12">
                            <div className="grid md:grid-cols-3 md:gap-6 gap-3">
                                <Gallery1 visibleId={visibleId} onClick={(id) => {
                                    if (id == visibleId) setVisibleId("");
                                    else setVisibleId(id);
                                }} />
                                <Gallery2 visibleId={visibleId} onClick={(id) => {
                                    if (id == visibleId) setVisibleId("");
                                    else setVisibleId(id);
                                }} />
                                <Gallery3 visibleId={visibleId} onClick={(id) => {
                                    if (id == visibleId) setVisibleId("");
                                    else setVisibleId(id);
                                }} />
                                <Gallery4 visibleId={visibleId} onClick={(id) => {
                                    if (id == visibleId) setVisibleId("");
                                    else setVisibleId(id);
                                }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="mt-20 pb-36">
                <div className="text-center">
                    <h4 className="text-lg font-semibold">
                        Want to join us at Renners?
                    </h4>
                    <Link to="/contact" replace={true} className="mt-6 rounded-lg bg-purple px-8 py-3 font-light text-white inline-block">
                        <div className="flex items-center gap-2">
                            <span>
                                Contact Us
                            </span>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13L7 7L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MediaPage;