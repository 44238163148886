import { getIcon, getImage, getLogo } from "../../utils";

const CareerPage = () => {
    return (
        <div>
            <div className="px-4 relative py-12 md:py-24 md:px-24">
                <div className="relative">
                    <div className="flex flex-col md:flex-row gap-16 items-center">

                        <div className="md:w-6/12 text-left">
                            <h4 className="text-purple font-bold text-3xl md:text-5xl">
                                Careers @ RennersNg
                            </h4>
                            <p className="mt-4 md:leading-10 md:text-2xl ">
                                At Reneers, our work culture is built on trust, well-being, results, and a healthy work-life balance.
                            </p>
                            <div className="md:mt-12 mt-6">
                                <button className="rounded text-[#5A5A5A] py-3 md:text-lg font-semibold">
                                    All Openings
                                </button>

                                <div className="mt-10 flex flex-col gap-8">
                                    <div className="flex items-center gap-2">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.25 3H13.425C13.2509 2.15356 12.7904 1.39301 12.1209 0.846539C11.4515 0.300068 10.6142 0.00109089 9.75 0L8.25 0C7.38585 0.00109089 6.54849 0.300068 5.87906 0.846539C5.20964 1.39301 4.74907 2.15356 4.575 3H3.75C2.7558 3.00119 1.80267 3.39666 1.09966 4.09966C0.396661 4.80267 0.00119089 5.7558 0 6.75L0 9H18V6.75C17.9988 5.7558 17.6033 4.80267 16.9003 4.09966C16.1973 3.39666 15.2442 3.00119 14.25 3ZM6.138 3C6.29256 2.56282 6.57842 2.18407 6.95648 1.91557C7.33453 1.64706 7.7863 1.50192 8.25 1.5H9.75C10.2137 1.50192 10.6655 1.64706 11.0435 1.91557C11.4216 2.18407 11.7074 2.56282 11.862 3H6.138Z" fill="#4896D1" />
                                            <path d="M9.75 11.25C9.75 11.4489 9.67098 11.6397 9.53033 11.7803C9.38968 11.921 9.19891 12 9 12C8.80109 12 8.61032 11.921 8.46967 11.7803C8.32902 11.6397 8.25 11.4489 8.25 11.25V10.5H0V14.25C0.00119089 15.2442 0.396661 16.1973 1.09966 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H14.25C15.2442 17.9988 16.1973 17.6033 16.9003 16.9003C17.6033 16.1973 17.9988 15.2442 18 14.25V10.5H9.75V11.25Z" fill="#4896D1" />
                                        </svg>
                                        <div>
                                            Senior Software Developer - Remote
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.25 3H13.425C13.2509 2.15356 12.7904 1.39301 12.1209 0.846539C11.4515 0.300068 10.6142 0.00109089 9.75 0L8.25 0C7.38585 0.00109089 6.54849 0.300068 5.87906 0.846539C5.20964 1.39301 4.74907 2.15356 4.575 3H3.75C2.7558 3.00119 1.80267 3.39666 1.09966 4.09966C0.396661 4.80267 0.00119089 5.7558 0 6.75L0 9H18V6.75C17.9988 5.7558 17.6033 4.80267 16.9003 4.09966C16.1973 3.39666 15.2442 3.00119 14.25 3ZM6.138 3C6.29256 2.56282 6.57842 2.18407 6.95648 1.91557C7.33453 1.64706 7.7863 1.50192 8.25 1.5H9.75C10.2137 1.50192 10.6655 1.64706 11.0435 1.91557C11.4216 2.18407 11.7074 2.56282 11.862 3H6.138Z" fill="#4896D1" />
                                            <path d="M9.75 11.25C9.75 11.4489 9.67098 11.6397 9.53033 11.7803C9.38968 11.921 9.19891 12 9 12C8.80109 12 8.61032 11.921 8.46967 11.7803C8.32902 11.6397 8.25 11.4489 8.25 11.25V10.5H0V14.25C0.00119089 15.2442 0.396661 16.1973 1.09966 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H14.25C15.2442 17.9988 16.1973 17.6033 16.9003 16.9003C17.6033 16.1973 17.9988 15.2442 18 14.25V10.5H9.75V11.25Z" fill="#4896D1" />
                                        </svg>
                                        <div>
                                            Legal Office - Hybrid
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="mt-8 ">
                                        We currently do not have an opening, please check again some <br /> other time or follow us on social media to get updates.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="md:w-4/12">
                            <img className="relative z-10" src={getImage('career.png')} alt="" />
                        </div>
                    </div>
                </div>

                <svg className="absolute right-0 bottom-0" width="57" height="380" viewBox="0 0 57 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="114" height="380" fill="#672A63" fillOpacity="0.63" />
                </svg>

            </div>
            <div className="px-4 md:px-20 py-12 md:py-28 mt-6 md:mt-12">
                <div className="md:text-left">
                    <h4 className="text-purple font-bold text-2xl md:text-4xl">
                        Work Benefits
                    </h4>
                </div>
                <div className="grid md:grid-cols-3 md:gap-16 gap-8 mt-12 md:mt-24">
                    <div className="bg-[#F8F8F8] px-4 py-6">
                        <div className="flex gap-2 items-center">
                            <div className="text-center">
                                <img className="" src={getIcon("health.png")} alt="" />
                            </div>
                            <h4 className="font-semibold text-xl">
                                Healthy work-life balance
                            </h4>
                        </div>
                        <div className="pt-3 pb-8">
                            <p className="text-justify">At Renners, our work schedules incorporate quality work ethics, fun and productivity. Ours is a wholesome work environment that promotes a balance of workflow, happiness, performance and profit among employees.</p>
                        </div>
                    </div>
                    <div className="bg-[#F8F8F8] px-4 py-6">
                        <div className="flex gap-2 items-center">
                            <div className="text-center">
                                <img className="" src={getIcon("calender.png")} alt="" />
                            </div>
                            <h4 className="font-semibold text-xl">
                                Flexible workout schedule
                            </h4>
                        </div>
                        <div className="pt-3 pb-8">
                            <p className="text-justify">
                                Our work schedule system prioritizes people and productivity. We allow for an alternative to the conventional workweek. Some of our employees are allowed to start and end a work day earlier than the scheduled time when work situation demands.
                            </p>
                        </div>
                    </div>
                    <div className="bg-[#F8F8F8] px-4 py-6">
                        <div className="flex gap-2 items-center">
                            <div className="text-center">
                                <img className="" src={getIcon("team.png")} alt="" />
                            </div>
                            <h4 className="font-semibold text-xl">
                                Annual team retreat
                            </h4>
                        </div>
                        <div className="pt-3 pb-8">
                            <p className="text-justify">
                                As an ambitious organization, our human resources principle is premised on work, play and effective team building. For this reason, on an annual basis, we organize team retreat and staff gathering designed to combine work, fun, team bonding.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-24   ">

                </div>
            </div>
        </div >
    )
}

export default CareerPage;