import { Link } from "react-router-dom";
import { getIcon, getImage, getLogo } from "../../utils";

const PrivacyPolicyPage = () => {
    return (
        <div>
            <div className="py-12 px-6 md:px-20">
                <h1 className="text-3xl text-left font-bold text-purple">

                    PRIVACY POLICY
                </h1>
                <div className="mt-8 text-left">
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        INTRODUCTION
                    </h3>

                    <p className="mt-4"> This Privacy Policy applies to Renners Investment Limited online interface (i.e., website or mobile application) and any Renners Investment Limited affiliate or subsidiary online interface that links to this Policy, (individually referred to as a 'site', and, collectively, 'sites'). The term 'the company' or 'Renners' or 'we' or 'us' or 'our' in this Policy refers to Renners Investment Limited and any affiliates or subsidiaries of Renners Investment Limited that links to this Policy. This Policy describes how the Sites may collect, use, and share information from or about you, and explains how information may be collected and used for advertising purposes.</p>

                    <p className="mt-4"> Renners Investment Limited recognizes your privacy rights as guaranteed under the 1999 Constitution of the Federal Republic of Nigeria; the Nigerian Data Protection Regulation, and other applicable privacy laws in Nigeria. Thus, it is important to us as a law-abiding organisation that your Personal Data is managed, processed and protected in accordance with the provisions of the applicable laws. In the course of our business and/or your engagement with us and third parties through our Platforms (this includes but are not limited to our websites, digital platforms, mobile applications, physical operations/offices, amongst others), we may process your Personal Data, subject however to the terms of this Policy. This Privacy Policy (“Policy”) therefore explains our privacy practices with respect to how we collect, process your Personal Data and describes your rights as a user of any of our services and Platforms.</p>

                    <p className="mt-4"> As a user of any of our Platforms, you accept this Privacy Policy when you sign up for, or use our products, services, content, technologies or functions offered on our Platforms and all related sites and services (“Services”).</p>

                    <p className="mt-4"> Our Privacy Policy explains our user’s privacy rights regarding:</p>
                    <ul className="pl-6 my-4">
                        <li> a.	What information we collect</li>
                        <li> b.	Why we collect such information</li>
                        <li> c.	The use of such information</li>
                        <li> d.	The storage, sharing and protection of such information</li>
                    </ul>
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DEFINITIONS
                    </h3>

                    <p className="mt4"> For the purpose of this Policy:</p>

                    <p className="mt4"> Personal Data/Information means information relating to you, including your name, location data, online identifier address, email address, pins, passwords, bank details, and other unique identifiers such as but not limited to IP address, IMEI number, Bank Verification Number, IMSI Number, SIM and others.</p>

                    <p className="mt4"> Process/Processing means any operation or set of operations which is performed on your Personal Data or sets of Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        PERSONAL DATA
                    </h3>
                    <p className="mt-4"> When utilizing Our Service, we may request that you provide us with certain personally identifiable information that can be used to contact or identify you, such as your email address, name, phone number, address, usage data, bank verification number, national identification number, photograph, date of birth, and image of a government-issued identification document (such as a national identification card, passport, driver's license, or voter ID card).</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DATA GATHERED DURING THE USAGE OF THE APPLICATION
                    </h3>

                    <p className="mt-4"> When using Our Application, we may collect and sync certain information from your device, with your prior permission. This information may include your location to confirm you are in Nigeria, SMS messages to validate your income for credit profiling and provide you with the best loan offers, pictures and other information from your device's camera, information about the apps installed on your device, your email accounts, and device permissions to access your phone number and network information, all in order to establish your device as trusted.</p>

                    <p className="mt-4"> This information is used to provide features of our service and to improve and customize our service. The information may be uploaded to our servers or a service provider's server, or it may be stored on your device. You have the ability to enable or disable access to this information at any time through your device settings.</p>

                    <p className="mt-4"> It's important to note that we will never communicate with any person from your contact list. In case you are nominating a person as your guarantor, you are also confirming that you have sought approval from the named person with regards to processing their data (name and contact details) and that they are willing to act as a guarantor for this loan facility.</p>


                    <h3 className="text-2xl text-purple font-bold mt-4">
                        PURPOSE OF THIS PRIVACY POLICY
                    </h3>

                    <p className="mt-4"> This privacy policy aims to give you information on how the Company collects and processes your personal data through your use of this website, including any data you may provide through this website when providing your financial information.</p>

                    <p className="mt-4"> This Policy is intended to govern the use of the Site by users. Users reserve the right to exercise their data protection rights as listed under the Customer Rights. For any access request, questions, or inquiries about how we use your Personal Information, please contact us at [•]  or write to us at our office at Suite 7,3rd Floor Lagos City Mall.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        CONSENT
                    </h3>

                    <p className="mt-4"> We kindly request that you carefully read through this Policy and click on the ‘Agree’ icon below if you consent to the terms of this Policy, particularly with respect to the processing of your Personal Data. That, notwithstanding, if you proceed to use any of our Platforms and services, it will deemed that you have provided your express consent to the terms of this Policy</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        AGE
                    </h3>

                    <p className="mt-4"> You must be at least 18 years old to use our services or any of our Platforms. Individuals under the age of 18, or applicable age of maturity, may utilize our Platforms services only with involvement of a parent or legal guardian, under such person’s account. Regardless, all Personal Data which we may process shall be in accordance with this Policy and other applicable laws.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        THIRD PARTY LINKS
                    </h3>

                    <p className="mt-4"> This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        COLLECTION OF DATA
                    </h3>

                    <p className="mt-4"> Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>

                    <p className="mt-4"> We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                    <ul className="pl-6 my-5">
                        <li> •	Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender. We may collect and process your information when you create and/or update your account on our Platform, complete forms, questionnaires, surveys etc.</li>
                        <li> •	Contact Data includes delivery address, email address and telephone numbers.</li>
                        <li> •	Financial Data includes bank account, payment card details and other relevant information.</li>
                        <li> •	Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
                        <li> •	Profile Data includes [your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses].</li>
                        <li> •	Usage Data includes information about how you use our website, products and services.</li>
                        <li> •	Social Media: includes your engagements with the Company on social media sites (e.g., Facebook, Instagram, LinkedIn, Twitter, etc.). This includes but are not limited to your replies to our posts, your comments, enquiries, messages to us, etc. We may also collect information from your public profile and updates on social media.</li>
                    </ul>
                    <p className="mt-4">  Third Parties: includes information from third parties such as, guardians, financial institutions, identity verification services, vendors, and service providers etc.</p>

                    <p className="mt-4">  Although it is not compulsory to give us this information if you do not then, we may not be able to provide you with the full range of services that the Site has to offer. Information is collected electronically and manually when you visit our website and register to use any of our services</p>
                    <p className="mt-4">  Information is collected electronically with exchanges between your system or service provider's system and our system.</p>

                    <p className="mt-4">  We collect your information manually when you complete our product and services registration forms in registering to use any of our services. Similar information is also collected when customers or visitors' visit our physical locations for inquiries or business relationship.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        ACCESSING YOUR PERSONAL INFORMATION
                    </h3>

                    <p className="mt-4"> When accessing our Platforms, we may collect information about you and your interactions with the Services to which we undertake to keep secure and confidential.</p>

                    <p className="mt-4"> We may offer you the ability to connect with our Platforms using a mobile device, either through a mobile application (App), computer, mobile optimized website, or by any other means. The provisions of this Privacy Policy apply to all such mobile access and use of mobile devices.</p>

                    <p className="mt-4"> When you download or use our mobile applications, or access one of our mobile optimized sites, we may receive information about your location and your mobile device, including a unique identifier for your device. We may use this information to provide you with location-based services, such as advertising, search results, and other personalized content. Most mobile devices allow you to control or disable location services in the device's setting's menu. If you have questions about how to disable your device’s location services, we recommend you contact your mobile service carrier or the manufacture of your particular device.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        USING YOUR PERSONAL INFORMATION
                    </h3>

                    <p className="mt-4"> In the course of your engagements with us or through our Platforms, we collect personal information for various legal reasons, largely to enable us to personalize your experience and to provide a more efficient service to you. Some of the reasons we collect Information are to:</p>
                    <ul className="pl-6 my-5">
                        <li> •	provide services and customer support;</li>
                        <li> •	process transactions, application requests, and send notices about transactions;</li>
                        <li> •	an account with us for the provision or use of our services;</li>
                        <li> •	communicate with you about your account or transactions with us and send you information or request feedback about features on our website and applications or changes to our policies;</li>
                        <li> •	send you periodic emails and updates pertaining to our products and services;</li>
                        <li> •	verify customers’ identity, including during account creation and password reset processes;</li>
                        <li> •	manage your account and provide you with efficient customer service,</li>
                        <li> •	send you offers and promotions for our services and investment opportunities</li>
                        <li> •	resolve disputes, process payments and troubleshoot problems;</li>
                        <li> •	detect, investigate and prevent activities that may violate our policies or be illegal;</li>
                        <li> •	manage risks, or to detect, prevent, and/or remediate fraud, violation of policies and applicable user agreements or other potentially prohibited or illegal activities;</li>
                        <li> •	execute our contractual obligations to you;</li>
                        <li> •	improve our services and functionality by customizing user experience;</li>
                        <li> •	measure the performance of our services and improve their content and layout;</li>
                        <li> •	manage and protect our information technology infrastructure;</li>
                        <li> •	provide targeted marketing and advertising, provide service or transaction update notices, and deliver promotional offers based on communication preferences;</li>
                        <li> •	obtain a means by which we may contact you; either by telephone, text (SMS), email messaging, social media, etc;</li>
                        <li> •	conduct background checks, compare information for accuracy and verify same with third parties;</li>
                        <li> •	identify or address a violation and administer our policies and terms of use;</li>
                        <li> •	comply with legal, contractual and regulatory obligations;</li>
                        <li> •	execute your specific requests or use same for a specific purpose as you may instruct;</li>
                        <li> •	investigate and respond to your complaints or enquiries;</li>
                        <li> •	process your access to our services, Platforms, or functions from time to time.</li>
                    </ul>
                    <p className="mt-4"> If we intend to use any Personal Information in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the personal information is required and obtain your consent.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        SECURITY OF DATA
                    </h3>

                    <p className="mt-4"> Renners protects your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure, and alteration. Renners runs a secure security architecture from applications, software, and processes to secure data from outsiders and abuse from insiders.</p>

                    <p className="mt-4"> Renners uses advanced tools to secure data at rest and data in transit. Renners complies with a management system for business continuity, quality management, information security and payment cards.</p>

                    <p className="mt-4"> Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centres, and information access authorization controls. We have also taken additional measures by ensuring our system complies with industry information security standards.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        USGAE OF DATA
                    </h3>

                    <p className="mt-4"> Usage Data refers to information that is automatically collected when you use the Service. This may include details such as your device's IP address, browser type and version, the pages of our Service that you visit, the time and date of your visit, the duration of time spent on those pages, unique device identifiers, and other diagnostic information.</p>

                    <p className="mt-4"> When you access the Service through a mobile device, we may also collect certain information automatically, such as the type of mobile device you are using, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser you use, and other diagnostic data. Additionally, we may collect information that your browser sends whenever you visit our Service or when you access it through a mobile device.</p>


                    <h3 className="text-2xl text-purple font-bold mt-4">
                        STORAGE AND PROTECTION OF DATA
                    </h3>

                    <p className="mt-4"> We protect your personal Information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, unauthorized disclosure and alteration. Some of our safeguards include firewall protection, Virtual Private Network (VPN) protection, encryption of data, authorization keys for authorized access and strong passwords. Access to our various platforms is restricted to authorized users only. Your Information is also stored on our secure servers as well as secure physical locations and cloud infrastructure (where applicable).   Please be aware that, despite our best efforts, no security measures are perfect or impenetrable. We will retain your personal information for the length of time needed to fulfil the purposes outlined in this privacy policy unless a longer retention period is required or permitted by law. To dispose of personal data, we may anonymize it, delete it or take other appropriate steps. Data may persist in copies made for backup and business continuity purposes for additional time.</p>

                    <p className="mt-4"> We will take all necessary measures to ensure that your personal Data is safe, however, you are also required to ensure that access codes, PINs, passwords, usernames, and all other information or hints that may enable third party access to your accounts on our Platforms are secure. We therefore strongly advise you to keep such information secure and confidential. If you use a third party’s device (laptops, phones, public internet, etc.) to access your account, kindly ensure that you always log out. Kindly note however that certain devices are programmed to save passwords or usernames, as such, we advise that you use third party devices with extreme caution. If you believe that an unauthorized person has accessed your information, please contact us immediately.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DATA RETENTION OF CUSTOMERS
                    </h3>

                    <p className="mt-4"> The Company will only keep your personal data for as long as it is necessary for the purposes outlined in this Privacy Policy. You have the right to request the deletion of any data we have collected about you. However, we may retain and use your personal data to comply with legal obligations, settle disputes, and enforce our legal agreements and policies.</p>

                    <p className="mt-4"> We will keep your data for as long as we have a relationship with you. Once our relationship with you has come to an end (e.g., following closure of your account or following a transaction), or your application for a product is declined or you decide not to go ahead with it, we will only retain information on our servers for as long as is reasonably necessary to fulfill the purposes we collected it for, including for the purpose of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>

                    <p className="mt-4"> Where you close your Account, your information is stored on our servers to the extent necessary to comply with regulatory obligations and for the purpose of fraud monitoring, detection, and prevention. Where we retain your Information, we do so in compliance with limitation periods under the applicable law while the retention period will be in line with the requirements of regulatory bodies in the Federal Republic of Nigeria.</p>

                    <p className="mt-4"> To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal regulatory, tax, accounting or other requirements.</p>



                    <h3 className="text-2xl text-purple font-bold mt-4">
                        PROCESSING YOUR INFORMATION
                    </h3>

                    <p className="mt-4">  To execute our obligations to you or process your transactions, we may be required to process your Information, such as your name, account number, account ID, contact details, shipping and billing address, or other information needed to complete the transaction. We also work with third parties, including financial institutions, vendors, service providers, who at one point or the other facilitate transactions executed on our Platforms. For completeness, in the course of your engagement with us or use of our services and Platforms, we may share your information with different stakeholders, including but not limited to financial institutions; service providers; Credit bureaus and collection agencies to report account or credit information; our subsidiaries and affiliates; Regulatory or judicial authorities; or other third parties pursuant to a subpoena, court order, or other legal process or applicable requirement. Please note that the aforementioned parties may be within or outside Nigeria.</p>

                    <p className="mt-4">  We may also process your Information when we believe, in our sole discretion, that the disclosure of your Information is necessary to comply with applicable laws and judicial/regulatory orders; conduct investigations; manage existing or imminent risks, prevent fraud, crime or financial loss, or for public safety or to report suspected illegal activity or to investigate violations of our Terms and Conditions.</p>

                    <p className="mt-4">  In all cases, we will ensure that your Information is safe, and notify the receiving party of the confidential nature of your Information, particularly the need to maintain the confidentiality of same and prevent unlawful or unauthorized usage.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        TRANSFER OF YOUR DATA
                    </h3>
                    <p className="mt-4"> Your information, including Personal Data, may be processed at the Company's offices and at any other locations where the parties involved in the processing are located. This means that your information may be transferred to and stored on computers located outside of your state, province, country, or other jurisdiction where data protection laws may differ from those in your jurisdiction.</p>

                    <p className="mt-4"> By consenting to this Privacy Policy and submitting your information, you agree to this transfer.</p>

                    <p className="mt-4"> The Company will take all reasonable steps to ensure that your data is treated securely and in accordance with this Privacy Policy. No transfer of your Personal Data will take place to any organization or country without appropriate controls in place, including the security of your data and other personal information.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DISCLOSURE OF DATA
                    </h3>

                    <p className="mt-4"> If the Company is involved in a merger, acquisition, or asset sale, your Personal Data may be transferred. We will notify you before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

                    <p className="mt-4"> In certain situations, the Company may be required to disclose your Personal Data if required by law or in response to valid requests by public authorities, such as a court or government agency.</p>

                    <p className="mt-4"> The Company may also disclose your Personal Data if we believe in good faith that such action is necessary to comply with a legal obligation, protect and defend our rights or property, prevent or investigate potential misconduct in connection with the Service, protect the safety of our users or the public, or protect against legal liability.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        PRIVACY OF CHILDREN
                    </h3>

                    <p className="mt-4"> Our Service is not intended for children under the age of 13. We do not knowingly collect personally identifiable information from children under the age of 13. If you are a parent or guardian and you become aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from a child under the age of 13 without verification of parental consent, we will take steps to remove that information from our servers. If you believe we may have information from or about a child under the age of 13, please contact us using the information provided in the 'Contact Us' section.</p>

                    <p className="mt-4"> In cases where we rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require the consent of your parent before collecting and using that information.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        WEBSITE’S LINK
                    </h3>

                    <p className="mt-4"> Our Service may include links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly recommend that you review the Privacy Policy of every site you visit.</p>

                    <p className="mt-4"> We do not have control over and are not responsible for the content, privacy policies or practices of any third-party sites or services.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        YOUR RIGHTS
                    </h3>

                    <p className="mt-4"> In regards to the personal information we have collected from you, you have certain rights under data protection laws. These rights include the ability to request access to your data and request the correction of any inaccurate or incomplete information we have on file.</p>

                    <p className="mt-4"> You have the right to request the deletion or removal of your personal data, where there is no valid reason for us to continue processing it. Additionally, you have the right to request deletion or removal of your personal data in cases where you have successfully objected to its processing, or where we have processed your information unlawfully or are required to do so by law. However, there may be certain legal reasons that prevent us from fulfilling your request for deletion. Additionally, you have the right to object to the processing of your personal data if we are relying on a legitimate interest or for direct marketing purposes. However, we may have compelling legitimate grounds that allow us to continue processing your information despite your objection.</p>

                    <p className="mt-4"> You have the right to request that we restrict the processing of your personal data in certain circumstances. This includes situations where you want us to verify the accuracy of the data, where our use of the data is unlawful but you don't want us to delete it, or where you need us to retain the data for the establishment, exercise, or defense of legal claims. Additionally, you have the right to request that we transfer your personal data to you or a third party in a structured, machine-readable format. However, this right only applies to information that was collected through automated means with your consent or as part of a contract with you.</p>

                    <p className="mt-4"> You have the right to withdraw your consent at any time for the processing of your personal data, however, this will not affect the legality of any processing that has been done before you withdraw your consent. If you do withdraw your consent, we may not be able to provide certain products or services to you, and we will inform you of this if it is the case. If you wish to exercise any of the rights previously mentioned, please contact us using the information provided in section 14. We will make an effort to respond to all legitimate requests within one month, but it may take longer if your request is complex or you have made multiple requests. In this case, we will inform you and keep you updated. Additionally, if you would like to raise a concern regarding a violation of this privacy policy, you can contact NITDA at ‘https://nitda.gov.ng/nit/contact-us/’</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DISCLOSURES
                    </h3>

                    <p className="mt-4">    We may share your personal information with:</p>
                    <ul className="pl-6 my-5">
                        <li> a.	Members of Renners to provide joint content, products and services (such as registration, transactions and customer support), to help detect and prevent potentially illegal acts and violations of our policies, and to guide decisions about their products, services, and communications. Members of the group will use this information to send you marketing communications and opportunities only if you have requested their services.</li>

                        <li> b.	Credit bureaus and collection agencies to report account or credit information, as permitted by law.</li>

                        <li> c.	Companies that we plan to merge with or are acquired by. (Should such a combination occur, we will require that the new combined entity follow this Privacy Policy with respect to your personal Information. If your personal Information could be used contrary to this policy, you will receive prior notice).</li>

                        <li> d.	Law enforcement, government officials, or other third parties pursuant to a subpoena, court order, or other legal process or requirement applicable to Renners or one of its agents; when we need to do so to comply with law; or when we believe, in our sole discretion, that the disclosure of personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate violations of our User Agreement.</li>

                        <li> Other unaffiliated third parties, for the following purposes</li>

                        <li> a.	Fraud Prevention and Risk Management: to help prevent fraud or assess and manage risk.</li>

                        <li> b.	Customer Service: for customer service purposes, including to help service your accounts or resolve disputes.</li>

                        <li> c.	Legal Compliance: to help them comply with anti-money laundering and counter-terrorist financing verification requirements.</li>

                        <li> d.	Service Providers: to enable service providers under contract with us to support our business operations, such as fraud prevention, marketing, customer service and technology services. Our contracts dictate that these service providers only use your information in connection with the services they perform for us and not for their own benefit.</li>
                    </ul>
                    <h3 className="text-2xl text-purple font-bold mt-4">
                        CHANGES TO YOUR INFORMATION
                    </h3>

                    <p className="mt-4">   Keeping your account information accurate and up to date is very important. If your account information is incomplete, inaccurate, or not current, please use the Contact Us option on our Site, or call or write to us at the telephone numbers or appropriate address for changes listed on your account statements, records, online or other account materials. You can also speak to a customer representative from the Company.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        SHARING THIS INFORMATION
                    </h3>

                    <p className="mt-4"> Certain services available on this Site are offered in conjunction with one or more of our business partners. For those services to be provided, we provide some necessary details about you to our business partners. We inform you about this at the point we collect that information on the Site. Please note that certain services may be unavailable if you do not want to disclose the personal information you are asked for.</p>

                    <p className="mt-4"> We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third party or as part of any business restructuring or reorganization, but we will take steps with the aim of ensuring that your privacy rights continue to be protected.</p>

                    <p className="mt-4"> In addition, we may pass your information onto one of our carefully selected business partners or to other carefully selected third parties to enable them to send you information which may be of interest to you but only if you have permitted us to do so. You can tell us to stop this at any time by sending an email.</p>

                    <p className="mt-4"> Other than as set out above, we will not disclose any of your personal information without your permission unless we are required by law to do so (for example, if required to do so by a court order or for prevention of fraud or other crime).</p>

                    <p className="mt-4"> If you choose to give us access to your account on a Third-Party Social Media Service, we may collect personal data that is already associated with your account, such as your name and email address, as well as information about your activities, all with your consent. Additionally, you may have the option of sharing additional information with us through your Third-Party Social Media Service account. If you choose to share such information, you are giving us permission to use, share, and store it in accordance with this Privacy Policy.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        SOFTWARE
                    </h3>

                    <p className="mt-4"> If you download or use our software, such as a stand-alone software product, an app, or a browser plugin, you agree that from time to time, the software may download and install upgrades, updates and additional features from us in order to improve, enhance, and further develop the software. We may utilise your personal information to internally evaluate our software.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        EXCEPTIONS
                    </h3>

                    <p className="mt-4"> Please note that this Policy does not apply to Information that is already in the public domain through no fault of ours.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        VIOLATION
                    </h3>

                    <p className="mt-4"> If you violate the letter or spirit of this Policy, or otherwise create risk or possible legal exposure for us or attempt to violate the privacy rights of Renners and/or its other users, we reserve the right to restrict your access to our Platforms. We will notify you if we are constrained to take such decision.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DISPUTE RESOLUTION
                    </h3>

                    <p className="mt-4"> We are dedicated to ensuring that you are satisfied with our management of your Information. However, in the unlikely event that you have a complaint, please contact us via the details below, stating your name and details of your complaint. Upon receipt of your complaint, we will endeavor to contact you within 3 (three) working days with a view to amicably resolving such dispute within the shortest possible time. The foregoing notwithstanding, all disputes arising from this policy shall first be resolved by negotiation. However, if parties fail to resolve the disputes amicably by such mutual consultations, parties shall further attempt to resolve the dispute by mediation.</p>

                    <h3 className="text-2xl text-purple font-bold mt-4">
                        DEFINATION OF TERMS
                    </h3>

                    <p className="mt-4"> The terms in this Privacy Policy are defined as follows:</p>

                    <p className="mt-4"> "Account" refers to a unique account created for the user to access the company's service or parts of it.</p>
                    <p className="mt-4"> "Affiliate" refers to an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</p>
                    <p className="mt-4"> "Application" refers to the software program provided by the company and downloaded by the user on any electronic device, named Renners.</p>
                    <p className="mt-4"> "Company" refers to Renners, located at Suite 7, 3Rd Floor Lagos City Mall, Onikan Lagos.</p>
                    <p className="mt-4"> "Cookies" are small files placed on the user's computer, mobile device, or any other device by a website, containing details of the user's browsing history on that website.</p>
                    <p className="mt-4"> "Country" refers to Nigeria.</p>
                    <p className="mt-4"> "Device" refers to any device that can access the service, such as a computer, cell phone, or digital tablet.</p>
                    <p className="mt-4"> "Personal Data" refers to any information that relates to an identified or identifiable individual.</p>
                    <p className="mt-4"> "Service" refers to the Application or the Website or both.</p>
                    <p className="mt-4"> "Service Provider" refers to any natural or legal person who processes data on behalf of the company, including third-party companies or individuals employed by the company to facilitate the service, provide the service on behalf of the company, perform services related to the service, or assist the company in analyzing how the service is used.</p>
                    <p className="mt-4"> "Third-party Social Media Service" refers to any website or social network website through which a user can log in or create an account to use the service.</p>
                    <p className="mt-4"> "Usage Data" refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (for example, the duration of a page visit).</p>
                    <p className="mt-4"> "Website" refers to Renners, accessible at https://rennersinvestmentltd.com</p>
                    <p className="mt-4"> "You" refers to the individual accessing or using the service, or the company or other legal entity on behalf of which such individual is accessing or using the service.</p>


                    <h3 className="text-2xl text-purple font-bold mt-4">
                        CONTACT US
                    </h3>

                    <p className="mt-4"> If you have questions regarding your data privacy rights or would like to submit a related data privacy right request, kindly contact us via the information below:</p>

                    <p className="mt-4"> Address: Suite 7, 3Rd Floor Lagos City Mall, Onikan Lagos</p>
                    <p className="mt-4"> Email: Anifat.Ayodeji@Rennersng.com</p>
                    <p className="mt-4"> Phone number: 08120326710</p>

                    <p className="mt-4"> Please allow up to 3 (three) working days for requests to be processed.  We reserve the right to charge a reasonable fee to process excessive or repeated requests.</p>


                    <h3 className="text-2xl text-purple font-bold mt-4">
                        AMENDMENT/CHANGES TO PRIVACY POLICY
                    </h3>

                    <p className="mt-4">Our Privacy Policy may be updated periodically. Any changes will be notified to you by posting the new policy on this page and through email or a prominent notice on our service. Before the changes take effect, the "Last updated" date at the top of the Privacy Policy will be updated. It is recommended that you review the Privacy Policy regularly for any updates. Your continued use of the service after changes are made signifies your acceptance of those changes.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage;