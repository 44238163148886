import { getIcon, getImage, getLogo } from "../../utils";
import { Carousel } from "@fancyapps/ui";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "@fancyapps/ui/dist/carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const NewsAndUpdateSection = () => {
    const ref = useRef<HTMLDivElement | null>();
    const carouselRef = useRef<Carousel | null>(null)

    const handleNext = () => {
        if (carouselRef.current)
            carouselRef.current?.slideNext()
    }

    useEffect(() => {
        const myCarousel = new Carousel(ref.current as HTMLDivElement, {
        });
        carouselRef.current = myCarousel;
        return () => {
            myCarousel.destroy()
        }
    }, [])

    return (
        <div className="mt-48 md:px-8  px-4">
            <div className="flex items-center text-left gap-2">
                <div className="bg-[#4896D1]  w-1/12 h-[2px]"></div>
                <div className="">
                    <h1 className="font-semibold text-2xl text-black">
                        News and Updates
                    </h1>
                </div>
                <div className="bg-[#4896D1] h-[2px] flex-1"></div>
            </div>
            <div className="relative">
                <div ref={ref as React.MutableRefObject<HTMLDivElement>} className="carousel">
                    <div className="carousel__viewport md:mt-24 mt-12 md:px-24">
                        <div className="carousel__slide md:!w-1/3">
                            <div className=" w-[260px]">
                                <img src={getImage("blog1.png")} alt="" className="w-9/12" />
                                <div className="text-slate-500 text-xs text-left mt-1">
                                    Aug 5, 2020
                                </div>
                                <h4 className="mt-3 text-left text-sm text-black">
                                    How Renners is improving digitalized community lending in West Africa.
                                </h4>
                            </div>
                        </div>
                        <div className="carousel__slide md:!w-1/3">
                            <div className=" w-[260px]">
                                <img src={getImage("blog1.png")} alt="" className="w-9/12" />
                                <div className="text-slate-500 text-xs text-left mt-1">
                                    Aug 5, 2020
                                </div>
                                <h4 className="mt-3 text-left text-sm text-black">
                                    How Renners is improving digitalized community lending in West Africa.
                                </h4>
                            </div>
                        </div>
                        <div className="carousel__slide md:!w-1/3">
                            <div className=" w-[260px]">
                                <img src={getImage("blog1.png")} alt="" className="w-9/12" />
                                <div className="text-slate-500 text-xs text-left mt-1">
                                    Aug 5, 2020
                                </div>
                                <h4 className="mt-3 text-left text-sm text-black">
                                    How Renners is improving digitalized community lending in West Africa.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={handleNext} className="md:block hidden self-center absolute right-0 top-0 mt-20">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z" stroke="#996F92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M30 40L40 30L30 20" stroke="#996F92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M20 30H40" stroke="#996F92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

interface CardProps {
    visibleId: string;
    onClick: (id: string) => void;
}
const ForBusiness: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "1"

    return (
        <div onClick={() => {
            onClick(id)
        }
        } className={`cursor-pointer relative h-[33.5rem]  bg-cover bg-center rounded-lg border-none flex  text-white ${visibleId == id ? 'px-6' : 'md:px-20 px-4 justify-center items-center'}`} style={{ backgroundImage: `url(${getImage("for-business.png")})` }}>
            {visibleId == id ? <div className="w-full h-full bg-white absolute left-0 rounded-lg bg-opacity-90">
            </div> : <div className="w-full h-full bg-black absolute left-0 rounded-lg bg-opacity-70">
            </div>}
            {visibleId == id ? <div className="text-left text-black relative z-10 pt-12">
                <div className="font-medium text-lg md:text-3xl uppercase">
                    Renners For Business
                </div>
                <p className="mt-4 md:text-xl text-sm font-normal !leading-relaxed">
                    <div className="md:text-xl font-bold">BIG BUSINESS IDEAS WORK WITH GOOD FINANCE</div>

                    Looking to expand your business or financial assistance for purchasing
                    machinery and other business expenses Talk to Renners now!
                    Renners SMEs loan is for you. Renners SMEs provides a loan system that is easy, fast and flexible.
                    Our loans for small and medium-scale businesses can be used to expand your business,
                    and refill inventory. It is designed for businesses with current credit problems who
                    have been denied traditional funding by banks..

                </p>
                <div className="mt-12">
                    <div className="flex justify-between items-center pr-10">
                        <button className="md:text-xl px-4 py-4 bg-[#622A59] text-white font-medium md:px-8 md:py-7 rounded-lg">
                            <div className="flex gap-4">
                                <div>
                                    Info@rennersng.com
                                </div>
                                <div>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2001_1981)">
                                            <path d="M24.9521 5.77295L16.1833 14.5417C15.2056 15.517 13.881 16.0647 12.5 16.0647C11.119 16.0647 9.7944 15.517 8.81667 14.5417L0.0479167 5.77295C0.0333333 5.93753 0 6.08649 0 6.25003V18.75C0.00165402 20.1309 0.550919 21.4547 1.52731 22.4311C2.50371 23.4074 3.82751 23.9567 5.20833 23.9584H19.7917C21.1725 23.9567 22.4963 23.4074 23.4727 22.4311C24.4491 21.4547 24.9983 20.1309 25 18.75V6.25003C25 6.08649 24.9667 5.93753 24.9521 5.77295Z" fill="white" />
                                            <path d="M14.7103 13.0687L24.2249 3.55308C23.764 2.78883 23.1139 2.15625 22.3374 1.71634C21.5608 1.27644 20.684 1.04406 19.7916 1.04163H5.20824C4.31576 1.04406 3.43897 1.27644 2.66243 1.71634C1.88589 2.15625 1.23582 2.78883 0.774902 3.55308L10.2895 13.0687C10.8765 13.6534 11.6713 13.9817 12.4999 13.9817C13.3285 13.9817 14.1233 13.6534 14.7103 13.0687Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2001_1981">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div>
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="16.5" transform="matrix(-1 0 0 1 16.5 16.5)" fill="#686666" />
                                <path d="M25 16.5264L10 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5264 11L10.0001 16.5263L15.5264 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div> : <div className="text-center relative z-10">
                <div className="font-medium text-2xl">
                    Renners For Business
                </div>

                <p className="mt-4 text-lg font-normal">
                    We provide loans for small and medium-scale businesses, to expand your business, purchase machineries and other business expenses.
                </p>
                <div className="relative md:-bottom-32 -bottom-24 text-center">
                    <svg className="inline" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="#686666" fillOpacity="0.65" />
                        <path d="M8 16.5264L23 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.4737 11L23 16.5263L17.4737 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            }

        </div>
    )
}

const ForInviduals: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "2"
    return (
        <div onClick={() => onClick(id)} className={`cursor-pointer relative h-[33.5rem]  bg-cover bg-center rounded-lg border-none flex  text-white ${visibleId == id ? 'px-6' : 'md:px-20 px-4 justify-center items-center'}`} style={{ backgroundImage: `url(${getImage("for-individual.png")})` }}>
            {visibleId == id ? <div className="w-full h-full bg-white absolute left-0 rounded-lg bg-opacity-90">
            </div> : <div className="w-full h-full bg-black absolute left-0 rounded-lg bg-opacity-70">
            </div>}
            {visibleId == id ? <div className="text-left text-black relative z-10 pt-12">
                <div className="font-medium text-lg md:text-3xl uppercase">
                    Renners For Individuals
                </div>
                <p className="mt-4 md:text-xl text-sm font-normal !leading-relaxed">
                    <div className="md:text-xl font-bold">  QUICK CASH, QUICK FIX</div>
                    Renners personal Loan is a line of credit for individual, secured by your salary or other income. You can use it to finance rent, school fees and all personal expenses.
                    Personal Loans for individuals are a quick, affordable and easy way to get the money you
                    need.
                    Apply online today or call our Hotlines to speak with our loan officers. Get Renners loan and start living your dream today!

                </p>
                <div className="mt-12">
                    <div className="flex justify-between items-center pr-10">
                        <button className="md:text-xl px-4 py-4 bg-[#622A59] text-white font-medium md:px-8 md:py-7 rounded-lg">
                            <div className="flex gap-4">
                                <div>
                                    Info@rennersng.com
                                </div>
                                <div>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2001_1981)">
                                            <path d="M24.9521 5.77295L16.1833 14.5417C15.2056 15.517 13.881 16.0647 12.5 16.0647C11.119 16.0647 9.7944 15.517 8.81667 14.5417L0.0479167 5.77295C0.0333333 5.93753 0 6.08649 0 6.25003V18.75C0.00165402 20.1309 0.550919 21.4547 1.52731 22.4311C2.50371 23.4074 3.82751 23.9567 5.20833 23.9584H19.7917C21.1725 23.9567 22.4963 23.4074 23.4727 22.4311C24.4491 21.4547 24.9983 20.1309 25 18.75V6.25003C25 6.08649 24.9667 5.93753 24.9521 5.77295Z" fill="white" />
                                            <path d="M14.7103 13.0687L24.2249 3.55308C23.764 2.78883 23.1139 2.15625 22.3374 1.71634C21.5608 1.27644 20.684 1.04406 19.7916 1.04163H5.20824C4.31576 1.04406 3.43897 1.27644 2.66243 1.71634C1.88589 2.15625 1.23582 2.78883 0.774902 3.55308L10.2895 13.0687C10.8765 13.6534 11.6713 13.9817 12.4999 13.9817C13.3285 13.9817 14.1233 13.6534 14.7103 13.0687Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2001_1981">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div>
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="16.5" transform="matrix(-1 0 0 1 16.5 16.5)" fill="#686666" />
                                <path d="M25 16.5264L10 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5264 11L10.0001 16.5263L15.5264 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div> : <div className="text-center relative z-10">
                <div className="font-medium text-2xl">
                    Renners For Individuals
                </div>
                <p className="mt-4 text-lg font-normal">
                    We are ready to provide you with personal loans to finance your projects, pay your rent, and salary advance.
                </p>

                <div className="relative md:-bottom-32 -bottom-24 text-center">
                    <svg className="inline" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="#686666" fillOpacity="0.65" />
                        <path d="M8 16.5264L23 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.4737 11L23 16.5263L17.4737 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            }

        </div>
    )
}

const ForLoan: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "3"
    return (
        <div onClick={() => onClick(id)} className={`cursor-pointer relative h-[33.5rem]  bg-cover bg-center rounded-lg border-none flex  text-white ${visibleId == id ? 'px-6' : 'md:px-20 px-4 justify-center items-center'}`} style={{ backgroundImage: `url(${getImage("for-banking.png")})` }}>
            {visibleId == id ? <div className="w-full h-full bg-white absolute left-0 rounded-lg bg-opacity-90">
            </div> : <div className="w-full h-full bg-black absolute left-0 rounded-lg bg-opacity-70">
            </div>}
            {visibleId == id ? <div className="text-left text-black relative z-10 pt-12">
                <div className="font-medium text-lg md:text-3xl uppercase">
                    Renners Instant Loan App
                </div>
                <p className="mt-4 md:text-xl text-sm font-normal !leading-relaxed">
                    <div className="md:text-xl font-bold">
                        EASY LENDING AT POCKET-FRIENDLY RATE.
                    </div>


                    Renners Instant Loan app is designed to help you get up to 500,000 naira loan without
                    collateral in minutes. It's a fast and secure way of getting money when you need it most, like medical bills, school fees, home support, holiday, upkeep etc.

                </p>
                <div className="mt-12">
                    <div className="flex justify-between items-center pr-10">
                        <button className="md:text-xl px-4 py-4 bg-[#622A59] text-white font-medium md:px-8 md:py-7 rounded-lg">
                            <div className="flex gap-4">
                                <div>
                                    Download the App
                                </div>
                                <div>
                                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6484 11.4404L3.87207 0.634766L17.583 8.50586L14.6484 11.4404ZM1.05957 0C0.424805 0.332031 0 0.9375 0 1.72363V23.2715C0 24.0576 0.424805 24.6631 1.05957 24.9951L13.5889 12.4951L1.05957 0ZM21.8213 11.0156L18.9453 9.35059L15.7373 12.5L18.9453 15.6494L21.8799 13.9844C22.7588 13.2861 22.7588 11.7139 21.8213 11.0156ZM3.87207 24.3652L17.583 16.4941L14.6484 13.5596L3.87207 24.3652Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div>
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="16.5" transform="matrix(-1 0 0 1 16.5 16.5)" fill="#686666" />
                                <path d="M25 16.5264L10 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5264 11L10.0001 16.5263L15.5264 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div> : <div className="text-center relative z-10">
                <div className="font-medium text-2xl">
                    Renners Instant Loan App
                </div>
                <p className="mt-4 text-lg font-normal">
                    Get up to 500,000 Naira loan without collateral, in minutes. Download the App to get started.
                </p>

                <div className="relative md:-bottom-32 -bottom-24 text-center">
                    <svg className="inline" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="#686666" fillOpacity="0.65" />
                        <path d="M8 16.5264L23 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.4737 11L23 16.5263L17.4737 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            }

        </div>
    )
}

const ForBanking: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "4"
    return (
        <div onClick={() => onClick(id)} className={`cursor-pointer relative h-[33.5rem]  bg-cover bg-center rounded-lg border-none flex  text-white ${visibleId == id ? 'px-6' : 'md:px-20 px-4 justify-center items-center'}`} style={{ backgroundImage: `url(${getImage("for-loan.png")})` }}>
            {visibleId == id ? <div className="w-full h-full bg-white absolute left-0 rounded-lg bg-opacity-90">
            </div> : <div className="w-full h-full bg-black absolute left-0 rounded-lg bg-opacity-70">
            </div>}
            {visibleId == id ? <div className="text-left text-black relative z-10 pt-12">
                <div className="font-medium text-lg md:text-3xl uppercase">
                    Renners Smart Banking
                </div>
                <p className="mt-4 md:text-xl text-sm font-normal !leading-relaxed">

                    <div className="md:text-xl font-bold">
                        MANAGE YOUR FINANCE THE SMART WAY.

                    </div>

                    Enjoy the ease of banking on your mobile device. Renners Smart Banking is a revolutionary way of managing your finances, where you can pay bills, buy
                    airtime and data, renew your cable tv subscription and more!
                </p>
                <div className="mt-12">
                    <div className="flex justify-between items-center pr-10">
                        <button className="md:text-xl px-4 py-4 bg-[#622A59] text-white font-medium md:px-8 md:py-7 rounded-lg">
                            <div className="flex gap-4">
                                <div>
                                    Coming Soon
                                </div>
                                <div>
                                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6484 11.4404L3.87207 0.634766L17.583 8.50586L14.6484 11.4404ZM1.05957 0C0.424805 0.332031 0 0.9375 0 1.72363V23.2715C0 24.0576 0.424805 24.6631 1.05957 24.9951L13.5889 12.4951L1.05957 0ZM21.8213 11.0156L18.9453 9.35059L15.7373 12.5L18.9453 15.6494L21.8799 13.9844C22.7588 13.2861 22.7588 11.7139 21.8213 11.0156ZM3.87207 24.3652L17.583 16.4941L14.6484 13.5596L3.87207 24.3652Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div>
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="16.5" transform="matrix(-1 0 0 1 16.5 16.5)" fill="#686666" />
                                <path d="M25 16.5264L10 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5264 11L10.0001 16.5263L15.5264 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div> : <div className="text-center relative z-10">
                <div className="font-medium text-2xl">
                    Renners Smart Banking

                </div>
                <p className="mt-4 text-lg font-normal">
                    Receive cash, transfer money, pay with your card, and carry out other daily transactions on Renners mobile banking App.
                    <div className="mt-4 text-blue-600 text-center">
                        Coming soon!
                    </div>
                </p>

                <div className="relative md:-bottom-32 -bottom-24 text-center">
                    <svg className="inline" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="#686666" fillOpacity="0.65" />
                        <path d="M8 16.5264L23 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.4737 11L23 16.5263L17.4737 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            }

        </div>
    )
}

const ForFinance: React.FC<CardProps> = ({ visibleId, onClick }) => {
    const id = "5"
    return (
        <div onClick={() => onClick(id)} className={`cursor-pointer relative h-[33.5rem]  bg-cover bg-center rounded-lg border-none flex  text-white ${visibleId == id ? 'px-6' : 'md:px-20 px-4 justify-center items-center'}`} style={{ backgroundImage: `url(${getImage("for-finance.png")})` }}>
            {visibleId == id ? <div className="w-full h-full bg-white absolute left-0 rounded-lg bg-opacity-90">
            </div> : <div className="w-full h-full bg-black absolute left-0 rounded-lg bg-opacity-70">
            </div>}
            {visibleId == id ? <div className="text-left text-black relative z-10 pt-12">
                <div className="font-medium text-lg md:text-3xl uppercase">
                    Renners Project Financing
                </div>
                <p className="mt-4 md:text-xl text-sm font-normal !leading-relaxed">
                    Financing a dream project can be herculean
                    when there is no help on the side. Renners Investment Limited are a strategic partner to work with when your decision is about lifting your project from ideas to reality.

                </p>
                <div className="mt-12">
                    <div className="flex justify-between items-center pr-10">
                        <button className="md:text-xl px-4 py-4 bg-[#622A59] text-white font-medium md:px-8 md:py-7 rounded-lg">
                            <div className="flex gap-4">
                                <div>
                                    Coming Soon
                                </div>
                                <div>
                                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6484 11.4404L3.87207 0.634766L17.583 8.50586L14.6484 11.4404ZM1.05957 0C0.424805 0.332031 0 0.9375 0 1.72363V23.2715C0 24.0576 0.424805 24.6631 1.05957 24.9951L13.5889 12.4951L1.05957 0ZM21.8213 11.0156L18.9453 9.35059L15.7373 12.5L18.9453 15.6494L21.8799 13.9844C22.7588 13.2861 22.7588 11.7139 21.8213 11.0156ZM3.87207 24.3652L17.583 16.4941L14.6484 13.5596L3.87207 24.3652Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div>
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="16.5" transform="matrix(-1 0 0 1 16.5 16.5)" fill="#686666" />
                                <path d="M25 16.5264L10 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5264 11L10.0001 16.5263L15.5264 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div> : <div className="text-center relative z-10">
                <div className="font-medium text-2xl">
                    Renners Project Financing

                </div>
                <p className="mt-4 text-lg font-normal">
                    Lifting your project from ideas to reality.
                </p>

                <div className="relative md:-bottom-32 -bottom-24 text-center">
                    <svg className="inline" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.5" cy="16.5" r="16.5" fill="#686666" fillOpacity="0.65" />
                        <path d="M8 16.5264L23 16.5264" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.4737 11L23 16.5263L17.4737 22.0526" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            }

        </div>
    )
}

const HomePage = () => {
    const [visibleId, setVisibleId] = useState("");
    const [paused, setPaused] = useState(false);
    const slider = useRef<Slider | null>(null);


    const settings = {
        infinite: true,
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    let settings2 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="overflow-hidden relative">
            <div className="relative">
                <div className="absolute w-full h-full">
                    <Slider className="relative w-full h-full" ref={slider}  {...settings}>
                        <div className="h-full">
                            <div className="relative top-0 w-screen h-full  bg-cover bg-center" style={{ backgroundImage: `url(${getImage("carousel-1.png")})` }}>
                                <div className="absolute w-full h-full bg-black bg-opacity-60 top-0" ></div>
                                <div className={`pt-24 pb-20  md:px-8 px-4  bg-opacity-30 relative h-full`}>
                                    <div className="md:w-11/12 mx-auto">
                                        <div className="mt-6 uppercase  md:text-2xl text-white flex gap-2 items-center justify-center">
                                            welcome to renners

                                        </div>
                                        <h1 className="relative z-10 md:text-[3.5rem] text-2xl md:leading-[1.3] font-bold text-white">
                                            We Provide The Right Financial Solutions <br className="md:inline hidden" /> For Your Business and Personal Needs
                                        </h1>

                                        <div className="mt-20 flex gap-6 justify-center">
                                            <a href="#explore" className="bg-[#FD6576] relative px-4 py-2 md:px-14 text-white  md:py-4  md:text-base text-sm rounded  font-semibold">
                                                <div className="flex items-center gap-2">
                                                    <div> EXPLORE</div>
                                                    <div className="relative">
                                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L6 6L11 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </a>

                                            <Link to="/contact" className="block bg-white relative px-4 py-2 md:text-base text-sm md:px-12 text-dark md:py-3 rounded font-semibold">
                                                <div className="flex items-center gap-2">
                                                    <div> CONTACT US</div>
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="relative text-center mt-20">
                                            <button onClick={() => {
                                                if (slider.current) {
                                                    if (!paused) {
                                                        slider.current.slickPause();
                                                        setPaused(true);
                                                        return;
                                                    }
                                                    slider.current.slickPlay();
                                                    setPaused(false)

                                                }
                                            }}>
                                                {!paused ? <svg className="inline" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.5 18.75V11.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.5 18.75V11.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> :
                                                    <svg className="inline" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14 26.5C20.9036 26.5 26.5 20.9036 26.5 14C26.5 7.09644 20.9036 1.5 14 1.5C7.09644 1.5 1.5 7.09644 1.5 14C1.5 20.9036 7.09644 26.5 14 26.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19.5 14.366C20.1667 13.9811 20.1667 13.0189 19.5 12.634L11.25 7.87083C10.5833 7.48593 9.75 7.96706 9.75 8.73686V18.2631C9.75 19.0329 10.5833 19.5141 11.25 19.1292L19.5 14.366Z" fill="white" />
                                                    </svg>}

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="relative top-0 w-screen h-full  bg-cover bg-center" style={{ backgroundImage: `url(${getImage("carousel-2.png")})` }}>
                                <div className="absolute w-full h-full bg-black bg-opacity-60 top-0" ></div>
                                <div className={`pt-24 pb-20  md:px-8 px-4  bg-opacity-30 relative h-full`}>
                                    <div className="md:w-11/12 mx-auto">
                                        <div className="mt-6 uppercase  md:text-2xl text-white flex gap-2 items-center justify-center">
                                            take the renners advantage

                                        </div>
                                        <h1 className="relative z-10 md:text-[3.5rem] text-2xl md:leading-[1.3] font-bold text-white">
                                            Whatever Business You Do, Let’s Go  <br className="md:inline hidden" /> There With You To Boost Your Profit.
                                        </h1>

                                        <div className="mt-20 flex gap-6 justify-center">
                                            <a href="#explore" className="bg-[#FD6576] relative px-4 py-2 md:px-14 text-white  md:py-4  md:text-base text-sm rounded  font-semibold">
                                                <div className="flex items-center gap-2">
                                                    <div> EXPLORE</div>
                                                    <div className="relative">
                                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L6 6L11 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </a>

                                            <Link to="/contact" className="block bg-white relative px-4 py-2 md:text-base text-sm md:px-12 text-dark md:py-3 rounded font-semibold">
                                                <div className="flex items-center gap-2">
                                                    <div> CONTACT US</div>
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="relative text-center mt-20">
                                            <button onClick={() => {
                                                if (slider.current) {
                                                    if (!paused) {
                                                        slider.current.slickPause();
                                                        setPaused(true);
                                                        return;
                                                    }
                                                    slider.current.slickPlay();
                                                    setPaused(false)

                                                }
                                            }}>
                                                {!paused ? <svg className="inline" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.5 18.75V11.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.5 18.75V11.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> :
                                                    <svg className="inline" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14 26.5C20.9036 26.5 26.5 20.9036 26.5 14C26.5 7.09644 20.9036 1.5 14 1.5C7.09644 1.5 1.5 7.09644 1.5 14C1.5 20.9036 7.09644 26.5 14 26.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19.5 14.366C20.1667 13.9811 20.1667 13.0189 19.5 12.634L11.25 7.87083C10.5833 7.48593 9.75 7.96706 9.75 8.73686V18.2631C9.75 19.0329 10.5833 19.5141 11.25 19.1292L19.5 14.366Z" fill="white" />
                                                    </svg>}

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="relative top-0 w-screen h-full  bg-cover bg-center" style={{ backgroundImage: `url(${getImage("carousel-3.png")})` }}>
                                <div className="absolute w-full h-full bg-black bg-opacity-60 top-0" ></div>
                                <div className={`pt-24 pb-20  md:px-8 px-4  bg-opacity-30 relative h-full`}>
                                    <div className="md:w-11/12 mx-auto">
                                        <h1 className="relative z-10 md:text-[3.5rem] text-2xl md:leading-[1.3] font-bold text-white">
                                            Making Life Simpler For You With Our <br className="md:inline hidden" /> Personal and Instant Loans
                                        </h1>

                                        <div className="mt-20 flex gap-6 justify-center">
                                            <a href="#explore" className="bg-[#FD6576] relative px-4 py-2 md:px-14 text-white  md:py-4  md:text-base text-sm rounded  font-semibold">
                                                <div className="flex items-center gap-2">
                                                    <div> EXPLORE</div>
                                                    <div className="relative">
                                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L6 6L11 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </a>

                                            <Link to="/contact" className="block bg-white relative px-4 py-2 md:text-base text-sm md:px-12 text-dark md:py-3 rounded font-semibold">
                                                <div className="flex items-center gap-2">
                                                    <div> CONTACT US</div>
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="relative text-center mt-20">
                                            <button onClick={() => {
                                                if (slider.current) {
                                                    if (!paused) {
                                                        slider.current.slickPause();
                                                        setPaused(true);
                                                        return;
                                                    }
                                                    slider.current.slickPlay();
                                                    setPaused(false)

                                                }
                                            }}>
                                                {!paused ? <svg className="inline" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.5 18.75V11.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.5 18.75V11.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> :
                                                    <svg className="inline" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14 26.5C20.9036 26.5 26.5 20.9036 26.5 14C26.5 7.09644 20.9036 1.5 14 1.5C7.09644 1.5 1.5 7.09644 1.5 14C1.5 20.9036 7.09644 26.5 14 26.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19.5 14.366C20.1667 13.9811 20.1667 13.0189 19.5 12.634L11.25 7.87083C10.5833 7.48593 9.75 7.96706 9.75 8.73686V18.2631C9.75 19.0329 10.5833 19.5141 11.25 19.1292L19.5 14.366Z" fill="white" />
                                                    </svg>}

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>

                <div className={`h-[85vh]`}>

                </div>
            </div>

            <div id="explore" className="md:px-8 px-4 lg:container mx-auto  py-16 md:py-36">
                <div className="flex md:flex-row flex-col gap-16">
                    <div className="md:flex grid grid-flow-col md:gap-6 gap-3">
                        <div>
                            <img className="md:h-auto md:w-auto w-[100px] h-[100px]" src={getImage("security.png")} alt="" />
                        </div>
                        <div className="text-left">

                            <h3 className="text-xl font-semibold text-purple">
                                100% Data Security
                            </h3>
                            <p className="md:text-justify text-sm mt-2">
                                Our priority at Rennersng is to safeguard and protect your personal information when collecting your data.
                            </p>
                        </div>
                    </div>
                    <div className="md:flex grid grid-flow-col md:gap-6 gap-3">
                        <div className="">
                            <img className="md:h-auto md:w-auto w-[100px] h-[100px]" src={getImage("disbursed.png")} alt="" />
                        </div>
                        <div className="text-left">

                            <h3 className="text-xl font-semibold text-purple">
                                Stress free process
                            </h3>
                            <p className="md:text-justify text-sm mt-2">
                                Our loan application process involves little or no paper documentation. We disburse fast, and our repayment system is dynamic.
                            </p>
                        </div>
                    </div>
                    <div className="md:flex grid grid-flow-col md:gap-6 gap-3">
                        <div>
                            <img className="md:h-auto md:w-auto w-[100px] h-[100px]" src={getImage("approvals.png")} alt="" />
                        </div>
                        <div className="text-left">
                            <h3 className="text-xl font-semibold text-purple">
                                +5K Daily Approvals
                            </h3>
                            <p className="md:text-justify text-sm mt-2">
                                At Rennersng, we approve hundreds of thousand loan amount on a daily basis. Our system is smart enough to offer you your eligible amount.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="md:mt-24 mt-12">
                    <div className="text-center">
                        <Link to="/about" className="text-dark underline">Learn more about us</Link>
                    </div>
                </div> */}
            </div>
            <div className="bg-purple text-white py-14 md:px-0 px-4">
                <div className="container mx-auto">
                    <div className="flex justify-between">
                        <div>
                            <div className="flex gap-2 items-center">
                                <svg width="14" height="5" viewBox="0 0 14 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 2.5H14" stroke="white" strokeWidth="4" />
                                </svg>
                                <div className="md:text-xl font-semibold">
                                    OUR
                                </div>
                            </div>
                            <div className="md:text-6xl text-3xl font-bold">
                                Solutions
                            </div>
                            <div className="text-lg">
                                We are here to help you power your growth
                            </div>
                        </div>
                        {/* <div className="self-end">
                            <button className="bg-white rounded-xl py-3 px-6 text-[#4896D1] text-base font-medium">
                                <div className="flex gap-2 items-center">
                                    <div>
                                        Explore all
                                    </div>
                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 4.6842L11 4.6842" stroke="#4896D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.3158 1L11 4.68421L7.3158 8.36842" stroke="#4896D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                        </div> */}
                    </div>

                    <div className="mt-10">
                        <div className="grid md:grid-cols-2 gap-16">
                            <ForBusiness visibleId={visibleId} onClick={(id) => {
                                if (id == visibleId) setVisibleId("");
                                else setVisibleId(id);
                            }} />
                            <ForInviduals visibleId={visibleId} onClick={(id) => {
                                if (id == visibleId) setVisibleId("");
                                else setVisibleId(id);
                            }} />
                            <ForLoan visibleId={visibleId} onClick={(id) => {
                                if (id == visibleId) setVisibleId("");
                                else setVisibleId(id);
                            }} />
                            <ForBanking visibleId={visibleId} onClick={(id) => {
                                if (id == visibleId) setVisibleId("");
                                else setVisibleId(id);
                            }} />
                            <ForFinance visibleId={visibleId} onClick={(id) => {
                                if (id == visibleId) setVisibleId("");
                                else setVisibleId(id);
                            }} />
                            <div className="w-full h-full text-center flex items-center justify-center">
                                <div>
                                    <Link to="/about" className="underline text-white">Learn more about us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-12 mt-24 md:px-24 text-left">
                <div className="flex md:flex-row flex-col-reverse items-center">
                    <div className="md:w-7/12">
                        <div className="md:rounded-l-xl border border-purple gradient pl-6 md:pl-14 pr-6 md:pr-24 pt-10 md:pb-10">
                            <div>
                                <h3 className="text-2xl md:text-[42px] font-bold text-[#622A59]">
                                    Do More With Renners
                                </h3>
                                <p className="mt-5 text-[#622A59] md:text-xl font-medium !leading-9">
                                    With the Renners new App, you can apply for instant loan and get credited in minutes. It is the easiest way to get quick cash without hassle, and it takes less than 5 minutes to register and complete your application.
                                </p>
                            </div>
                            <div className="mt-6">
                                <button className="text-purple bg-white font-medium rounded-lg py-4 px-5">
                                    <div className="flex gap-6">
                                        <div>
                                            Download the App
                                        </div>
                                        <div>
                                            <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.6484 11.4404L3.87207 0.634766L17.583 8.50586L14.6484 11.4404ZM1.05957 0C0.424805 0.332031 0 0.9375 0 1.72363V23.2715C0 24.0576 0.424805 24.6631 1.05957 24.9951L13.5889 12.4951L1.05957 0ZM21.8213 11.0156L18.9453 9.35059L15.7373 12.5L18.9453 15.6494L21.8799 13.9844C22.7588 13.2861 22.7588 11.7139 21.8213 11.0156ZM3.87207 24.3652L17.583 16.4941L14.6484 13.5596L3.87207 24.3652Z" className="fill-purple" />
                                            </svg>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="relative h-[310px] overflow-clip mt-8 md:hidden block">
                                <div className="relative">
                                    <div className=" -left-4"><img className="w-[210px]" src={getImage("app-2.png")} alt="" /></div>
                                    <div className="absolute left-[126px] top-0 z-10"><img className="w-[210px]" src={getImage("app-3.png")} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 md:block hidden  relative h-[425px]">
                        <div className="">
                            <div className="absolute -left-4"><img className="w-[210px]" src={getImage("app-2.png")} alt="" /></div>
                            <div className="md:absolute left-[116px] z-10 md:-top-4"><img className="w-[225px]" src={getImage("app-3.png")} alt="" /></div>
                            <div className="absolute left-[250px]"><img className="w-[210px]" src={getImage("app-1.png")} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-14 mt-8 md:mt-16 md:px-0 px-4">
                <div className="container mx-auto">
                    <div className="text-center">
                        <div>
                            <div className="flex gap-2 items-center justify-center">
                                <svg width="14" height="5" viewBox="0 0 14 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 2.5H14" stroke="black" strokeWidth="4" />
                                </svg>
                                <div className="text-lg md:text-xl font-semibold text-center">
                                    WHY CHOOSE RENNERS?
                                </div>
                            </div>
                            <div className="text-xl md:text-3xl mt-4 text-center">
                                We offer you varieties of products to service your financial requests. <br className=" md:inline hidden" /> We provide you accessible and Loans that fit your needs.
                            </div>
                            <div className="mt-4">

                            </div>
                        </div>
                    </div>

                    <div className="mt-24">
                        <div className="grid md:grid-cols-3 gap-12 md:px-36">
                            <div>
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M20.2375 2.5H9.77503C5.22503 2.5 2.51251 5.2125 2.51251 9.7625V20.225C2.51251 24.775 5.22503 27.4875 9.77503 27.4875H20.2375C24.7875 27.4875 27.5 24.775 27.5 20.225V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5Z" fill="#BCBABA" />
                                            <path d="M20 11.55H22.5C23.1875 11.55 23.75 10.9875 23.75 10.3V10C23.75 7.925 22.075 6.25 20 6.25H10C7.925 6.25 6.25 7.925 6.25 10V10.625C6.25 11.3125 6.8125 11.875 7.5 11.875H9.175C10.8125 11.875 12.3 13.05 12.4625 14.6875C12.65 16.5625 11.1875 18.1375 9.34999 18.1375H7.5C6.8125 18.1375 6.25 18.7 6.25 19.3875V20.0125C6.25 22.0875 7.925 23.7625 10 23.7625H20C22.075 23.7625 23.75 22.0875 23.75 20.0125V19.7C23.75 19.0125 23.1875 18.45 22.5 18.45H20C19.4875 18.45 19.0625 18.025 19.0625 17.5125C19.0625 17 19.4875 16.575 20 16.575H22.5C23.1875 16.575 23.75 16.0125 23.75 15.325V14.6875C23.75 14 23.1875 13.4375 22.5 13.4375H20C19.4875 13.4375 19.0625 13.025 19.0625 12.5C19.0625 11.975 19.4875 11.55 20 11.55Z" fill="#E14F4F" />
                                            <path d="M8.75 16.25H7.5C6.8125 16.25 6.25 15.6875 6.25 15C6.25 14.3125 6.8125 13.75 7.5 13.75H8.75C9.4375 13.75 10 14.3125 10 15C10 15.6875 9.4375 16.25 8.75 16.25Z" fill="#E14F4F" />
                                        </svg>
                                    </div>
                                    <div className="text-lg text-[#FD5467] font-semibold">
                                        Flexible Payment Plan
                                    </div>
                                </div>
                                <p className="mt-3 text-left">
                                    As a customer-centric company that provides lending facilities for different categories of
                                    customers, we
                                    don’t just finance businesses; we also guide them to grow with a payback plan that is business-friendly and allows mutual growth.

                                </p>
                            </div>

                            <div>
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M20.2375 2.5H9.7625C5.2125 2.5 2.5 5.2125 2.5 9.7625V20.225C2.5 24.7875 5.2125 27.5 9.7625 27.5H20.225C24.775 27.5 27.4875 24.7875 27.4875 20.2375V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5Z" fill="#1922AB" />
                                            <path d="M19.375 12.6624C20.6729 12.6624 21.725 11.6103 21.725 10.3124C21.725 9.01453 20.6729 7.9624 19.375 7.9624C18.0771 7.9624 17.025 9.01453 17.025 10.3124C17.025 11.6103 18.0771 12.6624 19.375 12.6624Z" fill="#1922AB" />
                                            <path d="M10.625 12.6624C11.9229 12.6624 12.975 11.6103 12.975 10.3124C12.975 9.01453 11.9229 7.9624 10.625 7.9624C9.32712 7.9624 8.27499 9.01453 8.27499 10.3124C8.27499 11.6103 9.32712 12.6624 10.625 12.6624Z" fill="#1922AB" />
                                            <path d="M19.5 18.75H10.5C9.62501 18.75 8.91251 16.8625 8.91251 17.75C8.91251 21.1125 11.65 23.85 15.0125 23.85C18.375 23.85 21.1125 21.1125 21.1125 17.75C21.1 16.875 20.375 18.75 19.5 18.75Z" fill="#1922AB" />
                                        </svg>

                                    </div>
                                    <div className="text-lg text-[#1922AB] font-semibold">
                                        Customer Friendly System
                                    </div>
                                </div>
                                <p className="mt-3 text-left">
                                    As a client-centric company, Renners comes to the table with a robust team that provides market-leading term funding solutions for numerous customers. We are Renners; we
                                    are real and reliable with our product offerings.
                                </p>
                            </div>

                            <div>
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M20.2375 2.5H9.77501C5.22501 2.5 2.51251 5.2125 2.51251 9.7625V20.225C2.51251 24.775 5.22501 27.4875 9.77501 27.4875H20.2375C24.7875 27.4875 27.5 24.775 27.5 20.225V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5Z" fill="#A50D8C" />
                                            <path d="M11.025 20.0249C10.7875 20.0249 10.55 19.9374 10.3625 19.7499C10 19.3874 10 18.7874 10.3625 18.4249L18.55 10.2375C18.9125 9.87495 19.5125 9.87495 19.875 10.2375C20.2375 10.6 20.2375 11.1999 19.875 11.5624L11.6875 19.7499C11.5 19.9374 11.2625 20.0249 11.025 20.0249Z" fill="#A50D8C" />
                                            <path d="M11.5375 9.57495C10.525 9.57495 9.6875 10.4 9.6875 11.425C9.6875 12.4375 10.5125 13.2749 11.5375 13.2749C12.55 13.2749 13.3875 12.45 13.3875 11.425C13.375 10.4 12.55 9.57495 11.5375 9.57495Z" fill="#A50D8C" />
                                            <path d="M19.7125 16.7375C18.7 16.7375 17.8625 17.5625 17.8625 18.5875C17.8625 19.6 18.6875 20.4375 19.7125 20.4375C20.725 20.4375 21.5625 19.6125 21.5625 18.5875C21.5625 17.5625 20.7375 16.7375 19.7125 16.7375Z" fill="#A50D8C" />
                                        </svg>

                                    </div>
                                    <div className="text-lg text-[#A50D8C] font-semibold">
                                        Competitive Interest Rate
                                    </div>
                                </div>
                                <p className="mt-3 text-left">
                                    Our interest rates are in sync with the market flow. In line with our aspiration to grow more with
                                    emerging enterprises and established corporations, we maintain a relatively stable and market-friendly rate that allows businesses within our fold to thrive.
                                </p>
                            </div>

                            <div>
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M20.2375 2.5H9.7625C5.2125 2.5 2.5 5.2125 2.5 9.7625V20.225C2.5 24.7875 5.2125 27.5 9.7625 27.5H20.225C24.775 27.5 27.4875 24.7875 27.4875 20.2375V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5Z" fill="#4B9388" />
                                            <path d="M8.60001 23.6251C8.08751 23.6251 7.66251 23.2001 7.66251 22.6876V20.1001C7.66251 19.5876 8.08751 19.1626 8.60001 19.1626C9.11251 19.1626 9.53751 19.5876 9.53751 20.1001V22.6876C9.53751 23.2126 9.11251 23.6251 8.60001 23.6251Z" fill="#4B9388" />
                                            <path d="M15 23.625C14.4875 23.625 14.0625 23.2 14.0625 22.6875V17.5C14.0625 16.9875 14.4875 16.5625 15 16.5625C15.5125 16.5625 15.9375 16.9875 15.9375 17.5V22.6875C15.9375 23.2125 15.5125 23.625 15 23.625Z" fill="#4B9388" />
                                            <path d="M21.4 23.6251C20.8875 23.6251 20.4625 23.2001 20.4625 22.6876V14.9126C20.4625 14.4001 20.8875 13.9751 21.4 13.9751C21.9125 13.9751 22.3375 14.4001 22.3375 14.9126V22.6876C22.3375 23.2126 21.925 23.6251 21.4 23.6251Z" fill="#4B9388" />
                                            <path d="M22.3375 7.275C22.3375 7.2125 22.3125 7.1375 22.3 7.075C22.2875 7.025 22.275 6.9625 22.2625 6.9125C22.2375 6.8625 22.2 6.825 22.175 6.775C22.1375 6.725 22.1 6.6625 22.05 6.625C22.0375 6.6125 22.0375 6.6 22.025 6.6C21.9875 6.575 21.95 6.5625 21.9125 6.5375C21.8625 6.5 21.8 6.4625 21.7375 6.4375C21.675 6.4125 21.6125 6.4125 21.55 6.4C21.5 6.3875 21.4625 6.375 21.4125 6.375H17.75C17.2375 6.375 16.8125 6.8 16.8125 7.3125C16.8125 7.825 17.2375 8.25 17.75 8.25H19.3125C16.3375 11.375 12.5875 13.575 8.37501 14.6375C7.87501 14.7625 7.56251 15.275 7.68751 15.775C7.78751 16.2 8.17501 16.4875 8.60001 16.4875C8.67501 16.4875 8.75001 16.475 8.82501 16.4625C13.2875 15.35 17.275 13.0375 20.4625 9.7625V10.975C20.4625 11.4875 20.8875 11.9125 21.4 11.9125C21.9125 11.9125 22.3375 11.4875 22.3375 10.975V7.3125C22.3375 7.3 22.3375 7.2875 22.3375 7.275Z" fill="#4B9388" />
                                        </svg>

                                    </div>
                                    <div className="text-lg text-[#4B9388] font-semibold">
                                        Top Notch Management
                                    </div>
                                </div>
                                <p className="mt-3 text-left">
                                    At Rennersng, we have a great management team with decades of industry
                                    experience. They know what makes lending business wholesome for
                                    individuals, business owners looking for finance. Our management team
                                    is composed of versatile industry professionals who understand business trends, corporate ethics,
                                    governance, and regulatory issues. Above all, they are futuristic and see each day as a new
                                    opportunity to add value to customers and stakeholders.
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="md:px-8 md:mt-24 mt-12">
                <div className="py-20  bg-cover bg-center md:px-20 px-4" style={{ backgroundImage: `url(${getImage("stories-bg.png")})` }}>
                    <div className="flex justify-center">
                        <div className="flex justify-center items-center gap-6">
                            <svg width="51" height="4" viewBox="0 0 51 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 2H51" stroke="white" strokeWidth="3" />
                            </svg>
                            <div className="text-xl md:text-5xl text-white font-bold">
                                Our Customer Stories
                            </div>
                            <svg width="51" height="4" viewBox="0 0 51 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 2H51" stroke="white" strokeWidth="3" />
                            </svg>
                        </div>
                    </div>
                    <div className="md:mt-24 mt-12">
                        <Slider className="relative w-full h-full"   {...settings2}>
                            <div className="pr-3">
                                <div className="bg-white rounded pt-8 pl-8 pr-8 pb-6 bg-opacity-90 flex flex-col justify-between h-[360px]">
                                    <p className="text-xl text-left">
                                        “ It took me less than 5 minutes to create an account on Renners quick loan app. My first loan was quick and i got the exact amount i applied for ”
                                    </p>

                                    <div className="flex gap-2">
                                        <div className="">
                                            <img src={getImage("career.png")} className="w-16" alt="" />
                                        </div>
                                        <div className="text-left mt-2 self-end">
                                            <span className="text-lg font-medium ">Oladele Abiodun</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-3">
                                <div className="bg-white rounded pt-8 pl-8 pr-8 pb-6 bg-opacity-90 flex flex-col justify-between h-[360px]">
                                    <p className="text-xl text-left">
                                        “ My investment with Renners is on rollover every now and then because they keep providing me with exciting offers and ad-ons on the amount i invested with them. ”

                                    </p>

                                    <div className="flex gap-2">
                                        <div className="">
                                            <img src={getImage("career.png")} className="w-16" alt="" />
                                        </div>
                                        <div className="text-left mt-2 self-end">
                                            <span className="text-lg font-medium ">Isiaku Danny</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-3">
                                <div className="bg-white rounded pt-8 pl-8 pr-8 pb-6 bg-opacity-90 flex flex-col justify-between h-[360px]">
                                    <p className="text-xl text-left">
                                        “ We have experienced massive growth in our business since we started doing business with Renners. They have provided us with over 10 million naira loan to grow our business and their repayment plan is very flexible . ”

                                    </p>

                                    <div className="flex gap-2">
                                        <div className="">
                                            <img src={getImage("career.png")} className="w-16" alt="" />
                                        </div>
                                        <div className="text-left mt-2 self-end">
                                            <span className="text-lg font-medium ">Oluyemi Adebayo</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="md:px-8 pt-28 md:mt-12">
                <div className="flex md:flex-row flex-col ">
                    <div className="md:w-5/12">
                        <img className="w-full" src={getImage("contact.png")} alt="" />
                    </div>
                    <div className="md:w-7/12 bg-[#F5F5F5] pt-8  px-4 md:px-20">
                        <div className="text-left">
                            <h3 className="font-bold text-3xl">
                                Get Free Consultation
                            </h3>
                            <p className="text-lg">
                                Please fill the form. An account officer will reach <br /> out to you as soon as possible.
                            </p>

                            <form action="mailto:Info@rennersng.com" method="post" encType="text/plain">
                                <div className="mt-4 flex flex-col gap-4">
                                    <div className="flex md:flex-row flex-col gap-4">
                                        <div className="flex-1">
                                            <input name="first-name" placeholder="Full Name" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full" />
                                        </div>
                                    </div>
                                    <div className="flex md:flex-row flex-col gap-4">
                                        <div className="flex-1">
                                            <input name="email-address" placeholder="Email Address" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full" />
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <input name="phone-number" placeholder="Phone Number" type="text" className="text-[#959595] mt-1 border-b pt-2 pb-4   border-b-[#959595] bg-transparent w-full" />
                                    </div>
                                    <div className="">
                                        <textarea placeholder="Your business need and description" name="comment" rows={4} cols={8} className="text-[#959595] mt-1 border-b pt-2 pb-4 border-b-[#959595] bg-transparent w-full"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button type="submit" className="px-12 py-3 text-white rounded bg-[#282828]">Submit</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="md:mt-52 mt-24 py-24 md:px-24 px-6  bg-[#110f0f]" style={{ backgroundImage: `url(${getImage("overlay.png")})` }}>
                <div className="flex md:flex-row flex-col justify-between items-center">
                    <div>
                        <h1 className="text-left md:text-6xl text-3xl font-bold text-[#FD5467]">Get Started Today!</h1>
                        <h4 className="text-2xl font-bold text-[#FD5467] text-left mt-2">
                            Apply for a loan or own an investment portfolio <br /> with us.
                        </h4>
                    </div>

                    <div className="flex justify-center gap-4 md:gap-8 md:mt-0 mt-4">
                        <div>
                            <Link to="/contact" replace={true} className="inline-block md:px-14 px-4 md:text-lg text-sm font-semibold py-3 text-[#5A5A5A] bg-white rounded-lg border-[2px] border-white">
                                Contact Us
                            </Link>
                        </div>
                        <div>
                            <button className="md:px-7 px-4 md:text-lg text-sm font-semibold py-3 bg-[#E0EEF9] rounded-lg border-[2px] border-white text-[#5A5A5A]">
                                <div className="flex gap-2 items-center">
                                    Apply for instant loan
                                    <img src={getIcon("play.svg")} alt="" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomePage;