import { motion, useCycle, Variants } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getImage } from "../../utils";


const Blogs = () => {
    return (
        <div>
            <div className="py-24 te">
                <div className="text-center">
                    <h1 className="md:text-5xl text-purple text-2xl font-bold">
                        Blog, News and Updates
                    </h1>

                </div>

                <div className="mt-12  mx-auto  px-4 text-left  md:text-lg container">
                    <div className="relative">
                        <div className="mt-6 md:mt-12">
                            <div className="grid md:grid-cols-3 md:gap-12 gap-3">
                                <div className="border rounded md:px-12 px-4 md:py-8 py-4 shadow-xl border-[#929292]">
                                    <div className="">
                                        <img src={getImage("blog1.png")} alt="" className="w-full" />
                                        <div className="text-slate-500 text-sm text-center mt-8">
                                            Aug 5, 2020
                                        </div>
                                        <h4 className="mt-5 text-center text-black">
                                            How Renners is improving digitalized community lending in West Africa.
                                        </h4>
                                        <div className="mt-12 mb-4 text-center">
                                            <button className="bg-[#622A59] text-white text-[15px]  rounded-xl px-8 py-1">
                                                Read
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded md:px-12 px-4 md:py-8 py-4 shadow-xl border-[#929292]">
                                    <div className="">
                                        <img src={getImage("blog1.png")} alt="" className="w-full" />
                                        <div className="text-slate-500 text-sm text-center mt-8">
                                            Aug 5, 2020
                                        </div>
                                        <h4 className="mt-5 text-center text-black">
                                            How Renners is improving digitalized community lending in West Africa.
                                        </h4>
                                        <div className="mt-12 mb-4 text-center">
                                            <button className="bg-[#622A59] text-white text-[15px]  rounded-xl px-8 py-1">
                                                Read
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded md:px-12 px-4 md:py-8 py-4 shadow-xl border-[#929292]">
                                    <div className="">
                                        <img src={getImage("blog1.png")} alt="" className="w-full" />
                                        <div className="text-slate-500 text-sm text-center mt-8">
                                            Aug 5, 2020
                                        </div>
                                        <h4 className="mt-5 text-center text-black">
                                            How Renners is improving digitalized community lending in West Africa.
                                        </h4>
                                        <div className="mt-12 mb-4 text-center">
                                            <button className="bg-[#622A59] text-white text-[15px]  rounded-xl px-8 py-1">
                                                Read
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded md:px-12 px-4 md:py-8 py-4 shadow-xl border-[#929292]">
                                    <div className="">
                                        <img src={getImage("blog1.png")} alt="" className="w-full" />
                                        <div className="text-slate-500 text-sm text-center mt-8">
                                            Aug 5, 2020
                                        </div>
                                        <h4 className="mt-5 text-center text-black">
                                            How Renners is improving digitalized community lending in West Africa.
                                        </h4>
                                        <div className="mt-12 mb-4 text-center">
                                            <button className="bg-[#622A59] text-white text-[15px]  rounded-xl px-8 py-1">
                                                Read
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded md:px-12 px-4 md:py-8 py-4 shadow-xl border-[#929292]">
                                    <div className="">
                                        <img src={getImage("blog1.png")} alt="" className="w-full" />
                                        <div className="text-slate-500 text-sm text-center mt-8">
                                            Aug 5, 2020
                                        </div>
                                        <h4 className="mt-5 text-center text-black">
                                            How Renners is improving digitalized community lending in West Africa.
                                        </h4>
                                        <div className="mt-12 mb-4 text-center">
                                            <button className="bg-[#622A59] text-white text-[15px]  rounded-xl px-8 py-1">
                                                Read
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded md:px-12 px-4 md:py-8 py-4 shadow-xl border-[#929292]">
                                    <div className="">
                                        <img src={getImage("blog1.png")} alt="" className="w-full" />
                                        <div className="text-slate-500 text-sm text-center mt-8">
                                            Aug 5, 2020
                                        </div>
                                        <h4 className="mt-5 text-center text-black">
                                            How Renners is improving digitalized community lending in West Africa.
                                        </h4>
                                        <div className="mt-12 mb-4 text-center">
                                            <button className="bg-[#622A59] text-white text-[15px]  rounded-xl px-8 py-1">
                                                Read
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="mt-20 pb-36">
                <div className="text-center">
                    <h4 className="text-lg font-semibold">
                        Want to join us at Renners?
                    </h4>
                    <Link to="/contact" replace={true} className="mt-6 rounded-lg bg-purple px-8 py-3 font-light text-white inline-block">
                        <div className="flex items-center gap-2">
                            <span>
                                Contact Us
                            </span>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13L7 7L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Blogs;